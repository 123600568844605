import React from 'react';
import { Text, View,  StyleSheet ,Image} from 'react-native';
import px from "../utils/px"
import navigate from './NavigatorUtil';
import CustomButton from './CustomButton';
import { BaseStyles } from './CommonStyle';
import payIcon from '../../assets/payIcon.png';
import vipIcon from '../../assets/vipIcon.png';
import FastImage from '../components/FastImage';

export default class RankVideoItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ref = React.createRef()
    }

    _onPressContent = () => {
        this.props.item.imgData = this.ref.current.getData();
        this.props.onPress ? this.props.onPress(this.props.item) : navigate('VideoDetail', { item: this.props.item });
    }

    render() {
        const width = this.props.width || px(352);
        const { id, name, tags, views, duration ,imageURL ,price=0} = this.props.item;
        return (
            <CustomButton
                onPress={() => {
                    this._onPressContent()
                }}
            >
                <View style={{ flexDirection:'row' , marginTop:px(20)}} 
                >
                    <>
                        <View style={{backgroundColor: BaseStyles.body_color , borderRadius:2}}>
                            <FastImage
                                cover={id}
                                style={{
                                    width,
                                    height: width * 9 / 16,
                                    borderRadius:2
                                }}
                                onRef={this.ref}
                            />
                            <Image style={{
                                width: px(100),
                                height: px(40),
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }} source={price >0 ? payIcon : vipIcon} />
                        </View>
                        <View style={styles.bottomView}>
                            <Text numberOfLines={1} style={{ fontSize: px(22), color: BaseStyles.name_color, fontWeight: 'bold', fontFamily: "System" }}>{name}</Text>
                            
                            <View style={styles.tagContent}>
                                {
                                    tags.map((tag, index) => {
                                        return (
                                            <View style={styles.tagItem} key={index.toString()}>
                                                <Text style={styles.keyText} >{tag}</Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>

                            <Text style={{ fontFamily: '', color: '#ffa31a', fontSize: px(20) }}>热度: {views || 0}</Text>
                            
                            
                        </View>
    
                    </>
                    
                </View>
            </CustomButton>
        )
    }
}


const styles = StyleSheet.create({
    bottomView: {
        marginLeft:px(8),
        width:px(200),
        justifyContent:'space-around'
    },

    tagContent: {
        marginTop: px(5),
        flexDirection: 'row',
        overflow: 'hidden'
    },

    tagItem: {
        backgroundColor: BaseStyles.tagItem_bg_color,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
        margin: px(2),
        paddingHorizontal: px(5)
    },

    keyText: {
        color: '#999999',
        fontSize: px(20),
        fontFamily: 'System',
        fontWeight: 'bold'
    },
});