import React from "react";
import { ImageBackground, Linking, Platform, Text, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import API from "../common/API";
import px from "../utils/px";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import axios from "axios";
import * as Application from "expo-application";
import { getItem, removeItem, setItem } from "../utils/Storage";
import * as Clipboard from 'expo-clipboard';
import { Buffer } from "buffer"
import StringUtils from "../utils/StringUtils";

const IpToHex = (ip) => {
  let arr = ip.split('.');
  let strHex = '';
  arr.forEach(l => {
    strHex += parseInt(l).toString(16)
  })
  return strHex;
}
const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return null;
}

export default class Splash extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tips: '正在获取配置',
      imageUrl: '',
      remainTime: 5,
      isLoad: false
    }
  }

  counFun() {
    this.timer = setInterval(() => {
      let t = this.state.remainTime - 1;
      if (t == 0) {
        clearInterval(this.timer);
        this.state.isLoad && $enter == 'auto' && this.props.navigation.navigate("HomeTabs", { screen: 'Home', params: { data: $appData.videoIndex } });
      }
      this.setState({
        remainTime: t,
      })

    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  async componentDidMount() {
    const empConfig = await getItem('APPCONFIG');
    if (empConfig) {
      $appConfig = empConfig;
    }
    let device = await getItem('DEVICE');
    let mobile = await getItem('MOBILE');
    let proxy = await getItem('PROXY');
    let inviter = await getItem('inviter');
    const url = 'https://api.ipify.org/';

    if (device) {
      this.startApp({ device, mobile, proxy ,inviter});
      return;
    }
    if (Platform.OS == 'web') {
      if (!proxy) {
        proxy = getQueryVariable('proxy');
      }
      if (!inviter) {
        inviter = getQueryVariable('inviter');
      }
      const webToken = getQueryVariable('token');
      if (webToken) {
        try {
          ({ proxy, device, mobile ,inviter} = JSON.parse(Buffer.from(webToken, 'base64').toString()));
          console.log(proxy, device, mobile,inviter);
        } catch (ex) {
          console.error(ex);
        }
      }
      if (!device) {
        try {
          const fp = await FingerprintJS.load();
          const result = await fp.get();
          let defaultIp = '100.100.100.100';
          const { data } = await axios.get(url);
          defaultIp = data;
          device = IpToHex(defaultIp) + result?.visitorId;
        } catch (ex) {
          device = StringUtils.randomString(40);
          console.error(ex);
        }
      }
    } else {
      device = Application.androidId;
      if (device == '0000000000000000' || !device) {
        device = StringUtils.randomString(40);
      }
      try {
        let infoClipboard = await Clipboard.getStringAsync();
        ({ proxy, mobile ,inviter} = JSON.parse(Buffer.from(infoClipboard, "base64").toString()));
      } catch (error) {
        console.error(error);
      }
    }
    await setItem('DEVICE', device);
    await setItem('PROXY', proxy);
    await setItem('MOBILE', mobile);
    await setItem('INVITER', inviter);
    this.startApp({ device, proxy, mobile ,inviter});
    // console.log("device", device)
  }

  startApp = async (loginInfo) => {
    this.timer && clearInterval(this.timer);
    const connect_init = await API.init(loginInfo,(tips) => {
      this.tips = tips;
      $ld(tips)
    }, (imageUrl) => {
      imageUrl && this.setState({ imageUrl });
      this.setState({ remainTime: 5 }, () => {
        this.counFun()
      })
    });
    if (!connect_init) {
      $ld(`网络错误-${this.tips}`, ()=>{
        this._onReload(loginInfo)
      });
      return;
    }
    this.setState({ isLoad: true }, async () => {
      $cld();
      if($enter == 'ignore'){
        this.props.navigation.navigate("HomeTabs", { screen: 'Home', params: { data: $appData.videoIndex } });
      }
    })
  }

  _onReload = (loginInfo) => {
    this.startApp(loginInfo)
  }

  jumpLogic = () => {
    if (this.state.isLoad && this.state.remainTime == 0) {
      this.props.navigation.navigate("HomeTabs", { screen: 'Home', params: { data: $appData.videoIndex } });
    } else {
      this.openAD()
    }
  }

  openAD = () => {
    const url = $launch?.url;
    if (!url) {
      return;
    }
    Linking.openURL(url)
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <TouchableWithoutFeedback
          onPress={this.openAD}>
          <ImageBackground
            resizeMethod="resize"
            style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0 }}
            source={this.state.imageUrl != '' ? {
              uri: this.state.imageUrl,
            } : require('../../assets/appIcon/splash.png')}
          />
        </TouchableWithoutFeedback>

        <TouchableOpacity style={{
          borderRadius: 20,
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: "absolute",
          bottom: 100,
          right: 30,
          borderWidth: 1,
          borderColor: '#fff',
          width: px(150),
          height: px(60)

        }}
          onPress={this.jumpLogic}>
          <Text style={{
            lineHeight: px(60),
            fontWeight: 'bold',
            fontFamily: '',
            fontSize: px(30),
            color: '#fff',
            textAlign: 'center'
          }}>{this.state.remainTime != 0 ? this.state.remainTime + 's' : '关闭'}</Text>
        </TouchableOpacity>
        <Text style={{ padding: px(20), position: 'absolute', fontFamily: '', fontSize: px(30), color: '#fff' }}>{Application.nativeApplicationVersion}</Text>
      </View>
    );
  }
}
