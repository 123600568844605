import React from 'react';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    ScrollView,
    Image,
    Pressable,
    RefreshControl,
    Linking,
    Platform,
} from 'react-native';
import { axiosAPI } from '../common/API';
import { CommonStyles } from '../common/CommonStyle';
import { windowWidth } from '../common/Platform';
import CoverLayer from '../components/CoverLayer';
import FastImage from '../components/FastImage';
import px from '../utils/px';
import { getItem } from '../utils/Storage';
import StringUtils from '../utils/StringUtils';

const timeArr = [ 2, 5, 8, 10];

const productArr = [
    'VIP_1','VIP_2','VIP_3','VIP_4','VIP_5'
];

const coinArr = [
    'COIN_1','COIN_2','COIN_3','COIN_4'
];

export default class Charge extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            hasTokenInfo: false,
            data: [],
            name: '',
            avatar: null,
            validity: '',
            refreshing: false,
            vipIndex: 0,
            coinIndex: 0,
            coinData: [],
            vipData: [],
            pageShow: 'day',

            channelIndex: 0,
            goPay: false
        }
    }

    componentDidMount = () => {
        this.viewDidAppear = this.props.navigation.addListener(
            'focus',
            this._tipReg
        )
    };

    _tipReg = async ()=>{
        let tokenInfo = await getItem('TOKENINFO');
        // console.log("22222",tokenInfo)

        if (StringUtils.isEmpty(tokenInfo)) {
            this.setState({hasTokenInfo:false})
        }else{
            this._loadData();
            this.setState({hasTokenInfo:true})
        }
    }

    componentWillUnmount=()=>{
        this.timer && clearInterval(this.timer);
    }

    _loadData = async () => {
        let isMobile = true;
        if (Platform.OS == 'web') {
            let u = navigator.userAgent;
            isMobile = !!u.match(/AppleWebKit.*Mobile.*/)
        }
        const { data, error } = await axiosAPI.post(`/order/channel` ,{ scan : !isMobile});
        let empData = data?.data || [];
        if (error) {
            $toast(error.message)
            return;
        }
        const coin = empData.find(el => el.key == 'coin');
        const vip = empData.find(el => el.key == 'day');

        const { name, avatar, validity ,id} = $infoData;

        this.setState({
            refreshing: false,
            data:empData,
            coinData: coin?.products || [],
            vipData: vip?.products || [],
            name: name,
            id:id,
            avatar: avatar,
            validity: validity
        });
    }

    _loadInfo =()=>{
        const { validity } = $infoData;

        this.setState({validity},()=>{
            this.props.navigation.navigate('Mine');
        }); 
    }

    counFun =()=>{
        this.timer && clearInterval(this.timer);
        this.polling();
        $hasOrder = true;
        let count = 1;
        this.timer = setInterval(() => {
            if (timeArr.findIndex(el=>el==count)>=0) {
                this.polling()
            }
            count+=1;
            if(count>10){
                this.timer && clearInterval(this.timer)
            }
          }, 1000);
    }

    _renderCoinContent(data) {
        return (
            <>
                {
                    data?.map((item, index) => {
                        let priceUrl = null;
                        // console.log("item.productID",item.productID)
                        switch (item.productID) {
                            case "COIN_1":
                                priceUrl = require("../../assets/pay/imgs_50.jpg")
                                break;
                            case "COIN_2":
                                priceUrl = require("../../assets/pay/imgs_100.jpg")
                                break;
                            case "COIN_3":
                                priceUrl = require("../../assets/pay/imgs_200.jpg")
                                break;
                            case "COIN_4":
                                priceUrl = require("../../assets/pay/imgs_500.jpg")
                                break;
                        }
                        return (
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    coinIndex: index
                                }, () => {
                                    this.scroll.scrollTo({ x: (px(280) + px(20)) * (index - 0.5), y: 0, animated: true })
                                });
                            }} key={index.toString()} >
                                {
                                    coinArr.includes(item.productID) ?  
                                        <Image resizeMode={'contain'} style={{ marginRight:px(20), opacity: this.state.coinIndex == index ? 1: 0.3, width: px(280), height: px(238)}} source={priceUrl} /> 
                                        :<FastImage
                                            url={item.resource}
                                            style={{ marginRight: px(20), opacity: this.state.coinIndex == index ? 1 : 0.3, width: px(280), height: px(238) }}
                                        />
                                }
                            </TouchableOpacity>
                        )
                    })
                }
            </>
        )
    }

    _renderVipContent(data) {
        return (
            <>
                {
                    data?.map((item, index) => {
                        let priceUrl = null;
                        switch (item.productID) {
                            case "VIP_1":
                                priceUrl = require("../../assets/pay/VIP_1.jpg")
                                break;
                            case "VIP_2":
                                priceUrl = require("../../assets/pay/VIP_2.jpg")
                                break;
                            case "VIP_3":
                                priceUrl = require("../../assets/pay/VIP_3.jpg")
                                break;
                            case "VIP_4":
                                priceUrl = require("../../assets/pay/VIP_4.jpg")
                                break;
                            case "VIP_5":
                                priceUrl = require("../../assets/pay/VIP_5.jpg")
                                break;
                        }
                        return (
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    vipIndex: index
                                }, () => {
                                    this.scroll.scrollTo({ x: (px(280) + px(20)) * (index - 0.5), y: 0, animated: true })
                                });
                            }} key={index.toString()} >
                                
                                {
                                    productArr.includes(item.productID) ?  
                                        <Image resizeMode={'contain'} style={{ marginRight:px(20), opacity: this.state.vipIndex == index ? 1: 0.3, width: px(280), height: px(238)}} source={priceUrl} /> 
                                        :<FastImage
                                            url={item.resource}
                                            style={{ marginRight: px(20), opacity: this.state.vipIndex == index ? 1 : 0.3, width: px(280), height: px(238) }}
                                        />
                                }
                            </TouchableOpacity>
                        )
                    })
                }
            </>
        )
    }

    polling = async () => {
        // console.log('执行polling');
        this.coverLayer1.hide();
        this.coverLayer2.hide()
        const { data, error } = await axiosAPI.post('/order/polling', { orderID: this.orderID });
        if (error) {
            $toast(error.message)
            return;
        }
        if (data?.finish) {
            this.timer && clearInterval(this.timer);
            $hasOrder = false;
            this._loadInfo();
        }
    }

    goPay = () => {
        this.setState({ goPay: true }, () => {
            Linking.openURL(this.url)
        })
    }

    _onClickPay = async (payItem) => {
        const { productID, channels } = payItem;
        const { channelID } = channels[this.state.channelIndex];
        $ld()
        const { data, error } = await axiosAPI.post('/order/create', { productID, channelID , source : $paySource });
        $cld()
        if (error) {
            $toast(error.message)
            return;
        }
        let url = data?.url;
        let orderID = data?.orderID;
        this.url = url;
        if (Platform.OS != 'web') {
            Linking.openURL(url).catch(err=>{
                console.log("errerr",err)
            })
        }
        this.orderID = orderID;
        this.coverLayer2.showWithContent(
            () => {
                return (
                    <View style={{ width: windowWidth, height: px(600), backgroundColor: '#262626' }}>

                        <Text style={{ color: '#fff', textAlign: 'center', paddingVertical: px(20), fontFamily: '', fontSize: px(30), borderBottomColor: '#fff', borderBottomWidth: 1 }}>充值确认中...</Text>
                        <Pressable onPress={() => {
                            this.polling();
                        }} style={{ padding: px(20), left: 0, top: 0, position: 'absolute' }}>
                            <Image style={{ width: px(32), height: px(32) }} source={require("../../assets/pay/close.png")} />
                        </Pressable>
                        {
                            (this.state.goPay || Platform.OS != 'web') ?
                                <View style={{ paddingHorizontal: px(20), flex: 1, justifyContent: 'space-around', marginBottom: px(100) }}>
                                    <Text style={{ color: '#fff', fontFamily: '', fontSize: px(30), paddingVertical: px(20) }}>如果您已成功充值,请点击已支付;如果支付失败,请再试一次或联系在线客服</Text>

                                    <TouchableOpacity onPress={this.counFun} >
                                        <View style={{ marginHorizontal: px(20), paddingVertical: px(30), backgroundColor: '#dfc0a0', borderRadius: px(42), alignItems: "center" }}>
                                            <Text>我已支付</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {
                                        this.coverLayer1.hide();
                                        this.coverLayer2.hide()
                                    }} >
                                        <View style={{ marginHorizontal: px(20), paddingVertical: px(30), borderWidth: 1, borderColor: '#dfc0a0', borderRadius: px(42), alignItems: "center" }}>
                                            <Text style={{ color: '#dfc0a0', fontFamily: '' }}>支付失败再试一次</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View> :
                                <View style={{ paddingHorizontal: px(20), flex: 1, justifyContent: 'center', marginBottom: px(100) }}>

                                    <TouchableOpacity onPress={this.goPay} >
                                        <View style={{ marginHorizontal: px(20), paddingVertical: px(30), backgroundColor: '#dfc0a0', borderRadius: px(42), alignItems: "center" }}>
                                            <Text>前往支付页面</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>

                        }

                    </View>

                )

            },
            () => {},
            CoverLayer.popupMode.bottom
        )
    }

    render() {
        const { data, coinData, vipData, pageShow, vipIndex, coinIndex, name, id ,avatar, validity ,hasTokenInfo } = this.state;
        let { isVIP, vipDate } = StringUtils.getVipTime(validity);

        return (
                <View style={CommonStyles.body}>
                    <ScrollView
                        refreshControl={
                            <RefreshControl
                                refreshing={this.state.refreshing}
                                onRefresh={this._loadData}
                                showsVerticalScrollIndicator={false}
                            />
                        }>
                            {
                                hasTokenInfo ? 
                                <View style={{ paddingHorizontal: px(20) }}>
                            <View style={{ flexDirection: 'row', marginTop: px(5) }} >
                                <Image source={{ uri: avatar }} style={{ borderRadius: 50, width: px(160), height: px(160) }} />
                                <View style={{ justifyContent: 'space-around', marginLeft: px(20) }}>
                                    <Text style={{ fontSize: px(30), fontWeight: "bold", fontFamily: "System", color: '#fff', alignItems: "center" }}>{name}</Text>
                                    <Text style={{ fontSize: px(22), fontWeight: "bold", fontFamily: "System", color: '#fff', alignItems: "center" }}>ID: {id}</Text>
                                    {isVIP ?
                                        <Text style={{ fontWeight: 'bold', fontFamily: 'System', fontSize: px(26), color: '#FF9900' }}>
                                            {vipDate}到期
                                        </Text>
                                        :
                                        <Text style={{ fontSize: px(26), fontWeight: "bold", fontFamily: "System", color: '#CBCBCB' }}>开通VIP会员获取更多特权</Text>
                                    }
                                </View>
                            </View>

                            <View style={{ flexDirection: 'row', marginTop: px(30), borderColor: '#474070', borderBottomWidth: 1 }}>
                                {
                                    data.map((channel, idx) => {
                                        return (
                                            <Pressable key={idx} onPress={() => {
                                                this.setState({
                                                    pageShow: channel.key
                                                });
                                            }} style={{
                                                flex: 1, backgroundColor: pageShow == channel.key ? '#F9B387' : '#262941',
                                                flexDirection: 'row', justifyContent: 'center', paddingVertical: px(10), borderTopLeftRadius: 4, borderTopRightRadius: 4
                                            }} >
                                                <Text style={{ fontSize: px(36), fontWeight: "bold", fontFamily: "System", color: pageShow == channel.key ? '#AC8458' : '#fff' }}>{channel.title}</Text>
                                            </Pressable>
                                        )
                                    })

                                }
                            </View>
                            {
                                <View style={{ marginTop: px(30) }} >
                                    <ScrollView
                                        ref={ref => this.scroll = ref}
                                        showsHorizontalScrollIndicator={false}
                                        horizontal={true}
                                    >
                                        {pageShow == 'coin' ? this._renderCoinContent(coinData) : this._renderVipContent(vipData)}

                                    </ScrollView>
                                </View>
                            }



                        </View> : 
                            <View style={{ paddingVertical:px(60),alignItems: "center" , justifyContent:'center'}} >
                                <Text onPress={() => { this.props.navigation.navigate('Login') }} style={{ fontSize:px(30),padding: px(10), borderRadius: 15, borderWidth: 1, borderColor: '#cccccc', color: '#cccccc', marginRight: px(18) }}>登录/注册
                                </Text>
                            </View>
                        }
                        


                        <Text style={{ marginTop: px(30), fontSize: px(36), fontWeight: "bold", fontFamily: "System", color: '#fff' }}>会员特权</Text>

                        <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                            <View style={styles.iconView}>
                                <Image source={require("../../assets/pay/yings.png")} style={{ width: px(70), height: px(70) }} />
                                <Text style={{ fontSize: px(24), fontWeight: "bold", fontFamily: "System", color: '#fff', marginVertical: px(5) }}>专享影片</Text>
                                <Text style={{ fontSize: px(18), fontWeight: "bold", fontFamily: "System", color: '#CECECE' }}>观看全站视频</Text>
                            </View>
                            <View style={styles.iconView}>
                                <Image source={require("../../assets/pay/jiasu.png")} style={{ width: px(70), height: px(70) }} />
                                <Text style={{ fontSize: px(24), fontWeight: "bold", fontFamily: "System", color: '#fff', marginVertical: px(5) }}>高速专线</Text>
                                <Text style={{ fontSize: px(18), fontWeight: "bold", fontFamily: "System", color: '#CECECE' }}>VIP高速线路</Text>
                            </View>
                            <View style={styles.iconView}>
                                <Image source={require("../../assets/pay/xiaoshuo.png")} style={{ width: px(70), height: px(70) }} />
                                <Text style={{ fontSize: px(24), fontWeight: "bold", fontFamily: "System", color: '#fff', marginVertical: px(5) }}>漫画小说</Text>
                                <Text style={{ fontSize: px(18), fontWeight: "bold", fontFamily: "System", color: '#CECECE' }}>阅览漫画小说</Text>
                            </View>

                            <View style={styles.iconView}>
                                <Image source={require("../../assets/pay/huiyuan.png")} style={{ width: px(70), height: px(70) }} />
                                <Text style={{ fontSize: px(24), fontWeight: "bold", fontFamily: "System", color: '#fff', marginVertical: px(5) }}>尊贵标识</Text>
                                <Text style={{ fontSize: px(18), fontWeight: "bold", fontFamily: "System", color: '#CECECE' }}>VIP彰显尊贵</Text>
                            </View>
                            <View style={styles.iconView}>
                                <Image source={require("../../assets/pay/taotu.png")} style={{ width: px(70), height: px(70) }} />
                                <Text style={{ fontSize: px(24), fontWeight: "bold", fontFamily: "System", color: '#fff', marginVertical: px(5) }}>套图资源</Text>
                                <Text style={{ fontSize: px(18), fontWeight: "bold", fontFamily: "System", color: '#CECECE' }}>美女模特写真</Text>
                            </View>

                            <View style={styles.iconView}>
                                <Image source={require("../../assets/pay/fenglou.png")} style={{ width: px(70), height: px(70) }} />
                                <Text style={{ fontSize: px(24), fontWeight: "bold", fontFamily: "System", color: '#fff', marginVertical: px(5) }}>凤楼性息</Text>
                                <Text style={{ fontSize: px(18), fontWeight: "bold", fontFamily: "System", color: '#CECECE' }}>全国约啪信息</Text>
                            </View>
                            <View style={styles.iconView}>
                                <Image source={require("../../assets/pay/fuli00.png")} style={{ width: px(70), height: px(70) }} />
                                <Text style={{ fontSize: px(24), fontWeight: "bold", fontFamily: "System", color: '#fff', marginVertical: px(5) }}>线下福利</Text>
                                <Text style={{ fontSize: px(18), fontWeight: "bold", fontFamily: "System", color: '#CECECE' }}>全国小姐姐空降</Text>
                            </View>

                            <View style={styles.iconView}>
                                <Image source={require("../../assets/pay/kefu.png")} style={{ width: px(70), height: px(70) }} />
                                <Text style={{ fontSize: px(24), fontWeight: "bold", fontFamily: "System", color: '#fff', marginVertical: px(5) }}>专属客服</Text>
                                <Text style={{ fontSize: px(18), fontWeight: "bold", fontFamily: "System", color: '#CECECE' }}>美女客服一对一</Text>
                            </View>



                        </View>

                        <Image style={{ alignSelf: 'center', marginTop: px(20), width: px(690), height: px(170) }} source={require("../../assets/src_image_vip_icon_youshengxiaoshuo.png")} ></Image>
                        <Image style={{ alignSelf: 'center', marginTop: px(20), width: px(690), height: px(170) }} source={require("../../assets/src_image_vip_icon_loufeng.png")} ></Image>
                        <Image style={{ alignSelf: 'center', marginTop: px(20), width: px(690), height: px(170) }} source={require("../../assets/src_image_vip_icon_zhibo.png")} ></Image>

                        {/* <View style={{ height: px(30),width: px(20)}}/> */}



                    </ScrollView>
                    {
                        hasTokenInfo ?   <TouchableOpacity onPress={() => {
                            const data = this.state.pageShow == 'coin' ? this.state.coinData[this.state.coinIndex] : this.state.vipData[this.state.vipIndex]
                            this.setState({ goPay: false });
                            this.coverLayer1.showWithContent(
                                () => {
                                    return (
                                        <View style={{ width: windowWidth, height: px(900), backgroundColor: '#262626', borderRadius: 10 }}>
                                            <Text style={{ marginVertical: px(20), textAlign: 'center', fontSize: px(30), color: "#fff", fontWeight: "bold", fontFamily: "System" }}>支付</Text>
    
                                            <Pressable onPress={() => {
                                                this.coverLayer1.hide()
                                            }} style={{ padding: px(20), right: 0, top: 0, position: 'absolute' }}>
                                                <Image style={{ width: px(32), height: px(32) }} source={require("../../assets/pay/close.png")} />
                                            </Pressable>
                                            <Text style={{ marginBottom: px(10), textAlign: 'center', fontSize: px(40), color: "#dfc0a0", fontWeight: "bold", fontFamily: "System" }}>¥<Text style={{
                                                fontSize: px(50)
                                            }}>{(data.tile / 100).toFixed(2)}</Text></Text>
                                            <ScrollView>
                                                <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
    
                                                    {
                                                        data.channels.map((item, index) => {
                                                            let iconUrl;
                                                            switch (item.payType) {
                                                                case "wechat":
                                                                    iconUrl = require("../../assets/pay/icon3.png")
                                                                    break;
                                                                case "alipay":
                                                                    iconUrl = require("../../assets/pay/icon4.png")
                                                                    break;
                                                            }
                                                            return (
                                                                <Pressable key={index} onPress={() => {
                                                                    this.setState({
                                                                        channelIndex: index
                                                                    })
                                                                }} style={{
                                                                    borderWidth: 1,
                                                                    borderColor: this.state.channelIndex == index ? '#dfc0a0' : '#fff',
                                                                    borderRadius: 5,
                                                                    width: windowWidth / 2 - px(50),
                                                                    height: px(150),
                                                                    marginVertical: px(20),
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: this.state.channelIndex == index ? 'rgba(210, 166, 121,0.5)' : null
                                                                }} >
                                                                    <View style={{ alignItems: "center", flexDirection: "row" }} >
                                                                        <Image style={{ width: px(41), height: px(41), marginRight: px(10) }} source={iconUrl} />
                                                                        <Text style={{ fontFamily: '', color: '#fff' }}>{item.alias}</Text>
                                                                    </View>
    
                                                                    {this.state.channelIndex == index ? <Image style={{ width: px(41), height: px(41), right: 0, bottom: 0, position: 'absolute' }} source={require("../../assets/pay/select.png")} /> : null}
                                                                </Pressable>
                                                            )
                                                        })
                                                    }
                                                </View>
                                            </ScrollView>
    
                                            <TouchableOpacity onPress={() => this._onClickPay(data)} >
                                                <View style={{ margin: px(50), paddingVertical: px(20), backgroundColor: '#dfc0a0', borderRadius: px(42), alignItems: "center" }}>
                                                    <Text>去支付</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
    
                                    )
    
                                },
                                () => this.coverLayer1.hide(),
                                CoverLayer.popupMode.bottom
                            )
                        }} >
                            <View style={{
                                margin: px(30),
                                paddingVertical: px(20),
                                backgroundColor: '#FF9900',
                                borderRadius: 23,
                            }} >
    
                                <Text style={{ textAlign: 'center', lineHeight: px(40), fontSize: px(30), color: "#fff", fontWeight: "bold", fontFamily: "System" }} >立即支付: {
                                    this.state.data.length > 0 ?
                                        <Text style={{ fontSize: px(34), color: "#fff", fontWeight: "bold", fontFamily: "System" }}>¥<Text style={{
                                            fontSize: px(40)
                                        }}>{
                                                pageShow == 'coin' ? (coinData[coinIndex].tile / 100).toFixed(2) : (vipData[vipIndex].tile / 100).toFixed(2)
                                            }</Text></Text>
                                        : null
                                }</Text>
                            </View>
                        </TouchableOpacity> : null
                    }
                   
                    <CoverLayer ref={ref => this.coverLayer1 = ref} />
                    <CoverLayer ref={ref => this.coverLayer2 = ref} />
                </View> 
        );
    }
}

const styles = StyleSheet.create({
    itemNameText: {
        fontWeight: "bold", fontFamily: "System", fontSize: px(40), color: "#fff"
    },

    itemPriceText: {
        color: "#fff", fontWeight: "600", fontFamily: "System", marginVertical: px(25)
    },

    iconView: {
        width: px(160), alignItems: 'center', backgroundColor: '#262626', paddingVertical: px(10),
        paddingHorizontal: px(2), margin: px(10) , borderRadius:5
    }
});