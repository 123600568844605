import md5 from 'md5';
import React from 'react';
import {
    View,
    Text,
    ScrollView,
    StyleSheet,
    FlatList,
    Image,
    Pressable
} from 'react-native';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import ListFooter from '../common/ListFooter';
import VideoItem from '../common/VideoItem';
import FastImage from '../components/FastImage';
import px from '../utils/px';
import { actor, assets } from '../utils/ResourceHelper';

export default class ActorDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            item: {},
            showFoot: 0,
            isRefreshing: false
        };
        this.totalPages = 0;
        this.currPage = 1;
    }

    componentDidMount = async () => {
        const item = await actor({ id: this.props.route.params.item.id });
        this.setState({
            item
        })
        this._loadData()
    };

    _loadData = async () => {
        const { data, totalPages } = await assets({ type: 'video', tag: this.props.route.params.item.nameCN, page: this.currPage ,pageSize :8});
        this.setState({
            data: this.state.data.concat(data),
            showFoot: totalPages > this.currPage ? 0 : 1,
            isRefreshing: false
        });
    };

    renderItem = ({ item }) => {
        return (
            <VideoItem item={item} width={px(352)} />
        )
    }

    /**标签上滑刷新 */
    _onEndReached = () => {
        if (this.state.showFoot != 0) {
            return;
        }
        this.currPage++;
        this.setState({ showFoot: 2 }, () => {
            this._loadData();
        });

    };

    render() {
        const { item: { avatar = null, desc = '-', height = '-', blood = '-', tags = [], nameEN = '-', nameCN = '-',
            nameJP = '-', cup = '-', info = '-', active = '-', product = '-', sign = '-', born = '-' }, data } = this.state;
        return (
            <View style={CommonStyles.body}>
                <Pressable onPress={() => { this.props.navigation.goBack() }} style={{ paddingHorizontal: px(30), paddingVertical: px(10) }}>
                    <Image
                        style={{ width: px(50), height: px(50) }}
                        source={require("../../assets/btn_back_default.png")}
                    />
                </Pressable>
                <View style={{ height: px(320), padding: px(10), flexDirection: 'row' }}>
                    <FastImage
                        url={avatar}
                        style={{
                            width: px(290),
                            borderRadius: 5
                        }}
                    />
                    <View style={{ flex: 1, justifyContent: 'space-around', paddingHorizontal: px(10) }} >
                        <Text allowFontScaling={true} numberOfLines={2} style={{ fontSize: px(50), color: BaseStyles.name_color, fontWeight: 'bold' }}>{nameCN}</Text>
                        <Text allowFontScaling={true} style={{ fontSize: px(28), color: BaseStyles.name_color, fontWeight: 'bold' }}>又名   <Text style={{ fontSize: px(22) }}>{nameJP}</Text></Text>
                        <Text allowFontScaling={true} style={{ fontSize: px(28), color: BaseStyles.name_color, fontWeight: 'bold' }}>英文   <Text style={{ fontSize: px(22) }}>{nameEN}</Text></Text>

                        <View style={{ flexDirection: 'row' }}>
                            <Text allowFontScaling={true} style={{ fontSize: px(28), color: BaseStyles.name_color, fontWeight: 'bold' }}>特点   </Text>
                            {
                                tags.map((tag, index) => {
                                    return (
                                        <View key={index} style={{
                                            marginRight: px(10),
                                            marginBottom: px(10),
                                            paddingVertical: px(5),
                                            paddingHorizontal: px(10),
                                            borderRadius: 2,
                                            backgroundColor: BaseStyles.tagItem_bg_color,
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <Text style={{ color: '#ffffff', fontWeight: "bold", fontSize: px(22) }}>{tag}</Text>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>

                </View>
                <View style={styles.tagContent}>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >国家: <Text style={styles.valueText}>{'日本'}</Text></Text>
                    </View>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >星座: <Text style={styles.valueText}>{sign}</Text></Text>
                    </View>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >年龄: <Text style={styles.valueText}>{born}</Text></Text>
                    </View>
                </View>

                <View style={styles.tagContent}>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >身高: <Text style={styles.valueText}>{height}</Text></Text>
                    </View>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >三围: <Text style={styles.valueText}>{info}</Text></Text>
                    </View>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >罩杯: <Text style={styles.valueText}>{cup}</Text></Text>
                    </View>
                </View>


                <View style={styles.tagContent}>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >血型: <Text style={styles.valueText}>{blood}</Text></Text>
                    </View>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >出道: <Text style={styles.valueText}>{active}</Text></Text>
                    </View>
                    <View style={styles.tagItem}>
                        <Text style={styles.keyText} >作品: <Text style={styles.valueText}>{product}</Text></Text>
                    </View>
                </View>
                <View style={{ height: px(100), marginBottom: px(20) }}>
                    <ScrollView>
                        <Text style={{ padding: px(10), color: BaseStyles.name_color, fontSize: px(22) }}>{desc}</Text>
                    </ScrollView>
                </View>

                <FlatList
                    data={data}
                    style={{ flex: 1 }}
                    contentContainerStyle={{ alignItems: 'center' }}
                    keyExtractor={item => item.id}
                    renderItem={this.renderItem}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    ListFooterComponent={() => <ListFooter showFoot={this.state.showFoot} page={this.currPage} />}
                    onEndReached={this._onEndReached}
                    onEndReachedThreshold={0.1}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    tagContent: {
        flexDirection: 'row'
    },

    tagItem: {
        backgroundColor: BaseStyles.tagItem_bg_color,
        borderRadius: 5,
        height: px(50),
        flex: 1,
        margin: px(5),
        padding: px(5)
    },

    keyText: {
        //
        color: '#999999',
        fontSize: px(28),
        fontFamily: 'System',
        fontWeight: 'bold'
    },

    valueText: {
        color: '#fff',
        fontFamily: 'System',
        textAlign: 'center',
        fontWeight: 'bold'
    },
});