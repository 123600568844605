import React from 'react'
import { Text, View, Image, Pressable } from 'react-native';
import px from "../utils/px"
import navigate from '../common/NavigatorUtil';
import ComicItem from '../common/ComicItem';
import { BaseStyles } from '../common/CommonStyle';

export default class ComicView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.tag
        }

        this.tag = this.props.tag;
    }

    acMore = () => {
        navigate("Result", { tag: this.tag , type :'comic' });
    }

    render() {
        return (
            <>
                <Pressable onPress={this.acMore}
                    style={{ marginLeft: px(10), flexDirection: "row", justifyContent: "space-between", alignItems: 'center', marginTop: px(25) }}>
                    <Text numberOfLines={1} style={{ fontSize: px(30), fontFamily: 'System', fontWeight: 'bold', color: BaseStyles.tag_title_color }}>{this.tag.title}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text numberOfLines={1} style={{ marginRight: px(5), fontSize: px(26), fontFamily: 'System', color: '#fff' }}>更多</Text>
                        <Image style={{ width: px(30), height: px(30), marginRight: px(20) }} source={require("../../assets/rightArrow.png")}></Image>
                    </View>
                </Pressable>

                <View style={{ flexDirection: 'row', flexWrap: 'wrap' ,justifyContent: 'space-evenly',  marginBottom:px(20)}}>
                    {
                        this.state.data.data.map((item) => {
                            return (
                                <ComicItem key={item.id} item={item} width={px(220)} />
                            )
                        })
                    }
                </View>
            </>
        )
    }
}