
import CryptoES from 'crypto-es';
import axios from "axios";
// import { platform } from '../common/Platform';
import * as FileSystem from 'expo-file-system';
import md5 from 'md5';
import { stringify as uuidStringify } from 'uuid';
import { Buffer } from "buffer"
import { Platform } from 'react-native';

const KEY = "baluobo1";

const PASSWORD = CryptoES.enc.Utf8.parse('wm2I0N0gVicjQT37');

const AES_CFG = {
    iv: CryptoES.enc.Utf8.parse('au79DE9fdQX44zvO'),//iv偏移量
    mode: CryptoES.mode.CBC,  //CBC模式
    padding: CryptoES.pad.Pkcs7//padding处理
};
export function DecodeComic(input, catalog) {
    if (!input) {
        return null;
    }
    const buffer = Buffer.from(input);
    const key = Buffer.from(KEY);
    let pos = 0;
    return catalog.map(length => {
        let decodeData = Buffer.alloc(length);
        for (let i = 0; i < length; i++) {
            decodeData[i] = buffer[i + pos] ^ key[i % key.length]
        }
        pos += length;
        return `data:image/jpeg;base64,${decodeData.toString('base64')}`
    })

}

const axiosAssets = axios.create({
    baseURL: 'https://assets.yt-vod.com/',
    timeout: 5000
});

axiosAssets.interceptors.request.use(
    (config) => {
        // config.startTime = new Date().getTime();
        config.responseType = 'arraybuffer';
        if (config.comic) {
            config.url = `comic/${config.comic}/cover.bin`;
            config.assetsType = 'image/jpeg';
        } else if (config.cover) {
            config.url = `cover/${config.cover}.bin`;
            config.assetsType = 'image/jpeg';
        }
        // else{
        //     config.url = `cover/eeaa746c-47f9-4f35-ab50-596d24b4dfd5.bin`;
        //     config.assetsType = 'image/jpeg';
        // }

        // headers: { 'Range': `bytes=${$appData.comicRowLength * page * pageSize}-${(page + 1) * $appData.comicRowLength * pageSize - 1}` }
        if (config.range) {
            const [start, end] = config.range;
            config.assetsType = 'image/jpeg';
            config.headers['Range'] = `bytes=${start}-${end}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error)
    },
);

axiosAssets.interceptors.response.use(
    (response) => {
        const cipher = CryptoES.algo.AES.createDecryptor(PASSWORD, AES_CFG);
        // response.config.decodeStart = new Date().getTime();
        const buffer = cipher.process(CryptoES.lib.WordArray.create(response.data))
        buffer.concat(cipher.finalize());
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        let { assetsType } = response.config;
        switch (response.config.url.split('/')[0]) {
            case 'gif':
                assetsType = 'image/gif';
                break;
            case 'png':
                assetsType = 'image/png';
                break;
            case 'jpg':
                assetsType = 'image/jpeg';
                break;
        }
        const now = new Date().getTime();
        // console.log(`res:${Math.floor(buffer.sigBytes/1024)}k: ${response.config.url} decode:${now-response.config.decodeStart} download:${response.config.decodeStart - response.config.startTime}`)
        switch (assetsType) {
            case 'image/gif':
            case 'image/png':
            case 'image/jpeg':
                return { data: `data:${assetsType};base64,${CryptoES.enc.Base64.stringify(buffer)}` };
            case 'json/text':
                return { data: JSON.parse(CryptoES.enc.Utf8.stringify(buffer)) };
            default:
                return { data: CryptoES.enc.Base64.stringify(buffer) };
        }

    },
    (error) => {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        return Promise.resolve({ error, data: null });
    }
);

export default axiosAssets;

export async function cover(id) {
    return await axiosAssets.get(`cover/${id}.bin`);
};

export async function resource(url) {
    const type = url.split('/')[0]
    let assetsType = null;
    switch (type) {
        case 'gif':
            assetsType = 'image/gif';
            break;
        case 'jpg':
            assetsType = 'image/jpeg';
            break;
        case 'png':
            assetsType = 'image/png';
            break;
        default:
            break;
    }
    return await axiosAssets.get(url, { assetsType });
};

export async function m3u8(token, id) {
    const cacheKey = `${id}.txt`;
    let data = cache.get(cacheKey);
    if (!data) {
        let startTime = new Date().getTime();
        ({ data } = await axios.get(`${$RES_ENDPOINT}${id}/seq.txt`, { responseType: "text" }));
        // console.log(`download cost:${new Date().getTime() - startTime}`);
        cache.set(cacheKey, data);
    } else {
        // console.log(`hit cache`);
    }
    const processStartTime = new Date().getTime();
    let playlist = data.split(',')
    let m3u8Data = [`#EXTM3U\n#EXT-X-VERSION:3\n#EXT-X-TARGETDURATION:${playlist[0]}\n#EXT-X-MEDIA-SEQUENCE:0\n#EXT-X-PLAYLIST-TYPE:VOD\n`,
    `#EXT-X-KEY:METHOD=AES-128,URI="${$API_ENDPOINT}key/${token}",IV=0x00000000000000000000000000000000\n`];


    playlist.forEach((seq, idx) => {
        if (idx == 0) {
            return;
        }
        m3u8Data.push(`#EXTINF:${seq},\n${$RES_ENDPOINT}${id}/playlist${idx - 1}.ts\n`)
    })
    m3u8Data.push('#EXT-X-ENDLIST\n')
    const m3u8 = m3u8Data.join('');
    if (Platform.OS === 'web') {
        // const blob = new Blob([m3u8])
        // return {type:'application/x-mpegurl', src: URL.createObjectURL(blob)};

        let u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (!isiOS) {
            const blob = new Blob([m3u8], { type: 'application/x-mpegURL' })
            return URL.createObjectURL(blob); //`data:application/x-mpegURL;base64,${Buffer.from(m3u8).toString('base64')}`;
        }
        return `data:application/x-mpegURL;base64,${Buffer.from(m3u8).toString('base64')}`;

    }

    const m3u8Uri = `${FileSystem.cacheDirectory}${id}.m3u8`;

    await FileSystem.writeAsStringAsync(m3u8Uri, m3u8)
    const { exists } = await FileSystem.getInfoAsync(m3u8Uri)

    const url = await FileSystem.getContentUriAsync(m3u8Uri);
    cache.set(m3u8Uri, url);
    // console.log(`decode cost:${new Date().getTime() - processStartTime}`);
    // console.log('m3u8Uri', exists, url)
    return url;
}

export async function mv(id) {
    const cacheKey = `${id}.mv`;
    const cachedURL = cache.get(cacheKey);
    if (cachedURL) {
        // console.log(`hit ${cacheKey}`)
        if (Platform.OS === 'web') {
            return cachedURL;
        }
        const { exists } = await FileSystem.getInfoAsync(cachedURL);
        if (exists) {
            return await FileSystem.getContentUriAsync(cachedURL);
        }
        // console.log(`not exists ${cachedURL}`);
    }
    const { data } = await axiosAssets.get(`mv/${id}.bin`);

    if (Platform.OS === 'web') {
        const buf = `data:video/mp4;base64,${data}`;
        cache.set(cacheKey, buf);
        return buf;
    }
    const mp4Uri = `${FileSystem.documentDirectory}${id}.mp4`;
    await FileSystem.writeAsStringAsync(mp4Uri, data, {
        encoding: FileSystem.EncodingType.Base64,
    })
    const url = await FileSystem.getContentUriAsync(mp4Uri);
    cache.set(cacheKey, mp4Uri);
    return url;
};


const xor = (buffer) => {
    for (let i = 0; i < buffer.length; i++) {
        buffer[i] = buffer[i] ^ 123;
    }
    return buffer;
}
const decodeIndex = (ab, schema) => {
    let offset = 0;
    let buffer = Buffer.from(ab);
    let arr = [];
    while (offset < buffer.length) {
        let obj = {};
        schema.forEach(({ key, type, length }) => {
            switch (type) {
                case 'uuid':
                    obj[key] = uuidStringify(buffer.slice(offset, offset + length)).toString();
                    break;
                case 'string': {
                    const strLen = buffer.readUInt8(offset);
                    obj[key] = xor(buffer.slice(offset + $appData.headerLength, offset + $appData.headerLength + strLen)).toString();
                }
                    break;
                case 'array': {
                    const strLen = buffer.readUInt8(offset);
                    obj[key] = xor(buffer.slice(offset + $appData.headerLength, offset + $appData.headerLength + strLen)).toString().split(',')
                }
                    break;
                case 'date':
                    obj[key] = buffer.slice(offset, offset + length).toString();
                    break;
                case 'number':
                    obj[key] = buffer.readUInt32BE(offset)
                    break;
            }
            offset += length;
        });
        arr.push(obj);
    }
    return { data: arr };
}
export async function comics({ key, page = 0, pageSize = 6 }) {
    if (!$appData.comicTags[key]) {
        return { data: [] }
    }
    const pages = Math.ceil($appData.comicTags[key] / pageSize);
    page %= pages;
    const response = await axios({
        url: `${$ASSETS_ENDPOINT}/COMIC/${key}.bin`,
        responseType: 'arraybuffer',
        headers: { 'Range': `bytes=${$appData.comicRowLength * page * pageSize}-${(page + 1) * $appData.comicRowLength * pageSize - 1}` }
    });
    const decodeData = decodeIndex(response.data, $appData.comicSchema);
    decodeData.totalPages = pages;
    return decodeData;
}

export async function videos({ key, page = 0, pageSize = 6 }) {
    if (!$appData.videoTags[key]) {
        return { data: [] }
    }
    const pages = Math.ceil($appData.videoTags[key] / pageSize);
    page %= pages;
    const response = await axios({
        url: `${$ASSETS_ENDPOINT}/VIDEO/${key}.bin`,
        responseType: 'arraybuffer',
        headers: { 'Range': `bytes=${$appData.videoRowLength * page * pageSize}-${(page + 1) * $appData.videoRowLength * pageSize - 1}` }
    });
    const decodeData = decodeIndex(response.data, $appData.videoSchema);
    decodeData.totalPages = pages;
    return decodeData;
}

export async function recommend({ type = 'video', tags }) {
    const assetsTags = type == 'video' ? $appData.videoTags : $appData.comicTags;
    const allKeys = Object.keys(assetsTags);
    const key = tags.map(tag => {
        const tagKey = md5(tag);
        if (assetsTags[tagKey]) {
            return tagKey;
        }
        return allKeys.randomMember();
    }).filter(t => t != null).randomMember();
    return await assets({ type, key, page: Math.floor(Math.random() * 100), pageSize: 10 });
}

export async function assets({ tag, key = md5(tag), page = 0, pageSize = 6, type = 'video' }) {
    page -= 1;
    page = Math.max(page, 0);
    switch (type) {
        case 'video':
            return await videos({ key, page, pageSize });
        case 'comic':
            return await comics({ key, page, pageSize });
    }
}
export async function actor({ idx, id, schema = 0 }) {
    const { data: actors } = $appData.actorIndex[schema];
    if (!actors) {
        return {};
    }
    if (idx == null) {
        idx = actors.findIndex(e => e.id == id);
    }
    if (idx < 0) {
        return {};
    }
    return actors[idx]
}