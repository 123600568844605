import React, { useState, useEffect } from 'react';
import { Image } from 'react-native';
import QRCode from 'qrcode-generator';

export default ({text, style}) => {
  const [base64Img, setBase64Img] = useState(undefined);
  useEffect(() => {
    const typeNumber = 4;
    const errorCorrectionLevel = 'L';
    const qr = QRCode(typeNumber, errorCorrectionLevel);
    qr.addData(text);
    qr.make();
    setBase64Img(qr.createDataURL());
  }, [text]);
  return (
    base64Img ?
      <Image source={{ uri: base64Img }} style={style} resizeMode="contain"/>
      :
      null
  );
};

