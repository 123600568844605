import React, { Component } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { TabView, TabBar } from 'react-native-tab-view';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import { windowWidth } from '../common/Platform';
import TopLoading from '../components/TopLoading';
import Events from '../utils/events';
import px from '../utils/px';
import TagPage from './TagPage';

export default class Comic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index: this.props.route.params?.idx || 0,
            routes: []
        }
    }

    componentDidMount = async () => {
        Events.addListener('changeComicTab', this._change)
        this.setState({ routes : $appData.comicIndex});
    };

    componentWillUnmount(){
        Events.removeListener('changeComicTab',this._change)
    }

    _change=(index)=>{
        this.setState({index})
    }

    renderScene = ({ route }) => {
        return (
            <>
            <View style={{width:windowWidth, backgroundColor:BaseStyles.tab_background_color , flexDirection:'row' , alignItems:'center', justifyContent:'space-around',paddingTop:px(10) , paddingBottom:px(25)}}>
                    <TouchableOpacity onPress={() => {
                        this.props.navigation.navigate('Search',{type : 'comic'});
                    }} >
                        <View style={{ width: px(615), height: px(60), backgroundColor: BaseStyles.input_color, borderRadius: 5,alignItems:'center',justifyContent:'flex-start',flexDirection:'row' }} >
                            <Image style={{ width: px(30), height: px(30) , marginHorizontal: px(20)}} source={require("../../assets/sous.png")} ></Image>
                            <Text style={{ fontSize: 13, color: BaseStyles.input_text_color }} >搜索更多漫画</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        this.props.navigation.navigate('History');
                    }} >
                        <Image style={{ width: px(45), height: px(45)}} source={require("../../assets/lishijil.png")} ></Image>
                    </TouchableOpacity>
                </View>
                <TagPage route={route} />
            </>
        )
    };

    renderTabBar = props => (
        <TabBar
            {...props}
            indicatorStyle={{ height:0 }}
            activeColor= {BaseStyles.tab_active_color}
            inactiveColor= {BaseStyles.tab_inactive_color}
            scrollEnabled
            tabStyle={{
                width: 'auto'
            }}
            style={{ backgroundColor: BaseStyles.tab_background_color }}
            renderLabel={({ route, focused, color }) => (
                <View style = {{ flexDirection:'row' ,alignItems:'center' ,alignSelf:'center'}}>
                    <Text style={{ color , fontSize: px(28) ,fontWeight: 'bold', fontFamily: "System"}} >
                    {route.title}
                    </Text>
                </View>
              )}
        />
    );

    render() {
        return (
            <View style={CommonStyles.body}>
                {
                    this.state.routes.length > 0 ? <TabView
                        renderTabBar={this.renderTabBar}
                        navigationState={this.state}
                        renderScene={this.renderScene}
                        onIndexChange={this._change}
                        initialLayout={{ width: windowWidth ,height:0}}
                        lazy
                        lazyPreloadDistance={0}
                        renderLazyPlaceholder={()=> <TopLoading />}
                    /> : <TopLoading />
                }
            </View>
        );
    }
}