import { Platform, Dimensions, StatusBar } from 'react-native';

const X_WIDTH = 640;
const X_HEIGHT = 1136;

export const { height, width } = Dimensions.get('window');
export const aspect = width / height;
export const windowWidth = aspect >= 0.75 ? Math.floor(height / 4 * 3) : width;
export const windowHeight = height;
// console.log(aspect, width, height, windowWidth,windowHeight)

export const STATUS_BAR_HEIGHT = Platform.OS === 'ios' ? 0 : StatusBar.currentHeight;

export const isAndroid = () => {
    if (Platform.OS === 'android')
        return true;
    return false;
};

export const isIOS = () => {
    if (Platform.OS === 'ios')
        return true;
    return false;
};
const platform = Platform.OS;
export {
    platform,
}


