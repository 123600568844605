import React, { Component } from "react";
import { Modal, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import px from "../utils/px";
import { windowHeight, windowWidth } from '../common/Platform';
import Events from "../utils/events";


export default class ModalTip extends Component {
  constructor() {
    super();
    this.state = {
      modalVisible: false,
      modalOptions: {}
    };
  }

  componentDidMount() {
    Events.addListener("modal", this._modal)
  }

  _modal = (options) => {
    this.setState({
      modalVisible: true,
      modalOptions: options
    })
  }


  render() {
    const { modalVisible, modalOptions: { text, confirmTip = '去充值', cancelTip = '取消', callback = null,cancelCallback=null } } = this.state;
    return (
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => { }}
      >
        <View style={styles.modalView}>
          <View style={styles.content}>
            <Text style={styles.tips}>{text}</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <TouchableOpacity onPress={() => {
                this.setState({
                  modalVisible: false,
                  modalOptions: {}
                },()=>{
                  cancelCallback && cancelCallback()
                })
              }}
                style={[styles.btn, styles.buttonClose]}>
                <Text style={styles.btnText}>{cancelTip}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.btn}
                onPress={() => {
                  this.setState({
                    modalVisible: false,
                    modalOptions: {}
                  },()=>{
                    callback && callback()
                  })
                }}
              >
                <Text style={styles.btnText}>{confirmTip}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0,0,0,0.4)'
  },
  content: {
    width: windowWidth * 0.8,
    minHeight: windowHeight * 0.12,
    borderRadius: 10,
    backgroundColor: "#fff",
  },
  tips: {
    fontSize: px(28),
    lineHeight: px(40),
    minHeight: px(80),
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    padding: px(20),
  },
  btn: {
    textAlign: "center",
    height: px(100),
    flex: 1,
  },

  buttonClose: {
    borderRightWidth: 1,
    borderRightColor: '#eee'
  },
  btnText: {
    textAlign: "center",
    lineHeight: px(100),
    fontSize: px(30),
    color: "#0089e6",
    fontFamily: '',
  }
});