import React, { Component } from 'react';
import {
    TouchableOpacity,
    View,
    Text,
    StyleSheet,
    FlatList,
    Pressable,
    Image,
} from 'react-native';
import ContentModal from '../common/ContentModal';
import { windowWidth } from '../common/Platform';
import px from '../utils/px';
import FastImage from '../components/FastImage';
import TopLoading from '../components/TopLoading';
import { axiosAPI } from '../common/API';
export default class ComicPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showBtn: false
        };
        this.allContent = this.props.route.params.allContent;
        this.chapter_id = this.props.route.params.id;
        this.currentIdx = this.props.route.params.idx;
    }

    componentDidMount() {
        this._loadData()
    };

    _loadData = async () => {
        if($hasOrder){
            await axiosAPI.post(`info`, {place:'ComicPlay'});
        }
        const { catalog, chapter_num } = this.allContent[this.currentIdx];
        this.props.navigation.setOptions({ title: `第${chapter_num}话` })
        let offset = 0;
        let data = catalog.map(length => {
            offset += length;
            return { id: this.chapter_id, range: [offset - length, offset-1], chapter: ('0000' + chapter_num).slice(-3) }
        })

        if(!$infoData.vip){
            data = data.slice(0,10);
        }else{
            $hasOrder = false;
        }
        this.setState({
            data
        });

    }

    _renderItem = ({ item: { id, range, chapter } ,index}) => {
        if(!$infoData.vip && index == 9){
            return(
                <Pressable onPress={()=>{
                    $box({
                        text: '非会员仅能观看部分漫画,充值VIP可无限制观看!',
                        callback: () => {
                            $paySource = 'COMIC';
                            this.props.navigation.navigate('Charge')
                        }
                    })
                }}>
                    <Image style={{
                        width: windowWidth,
                        height: windowWidth * 2
                    }} source={require("../../assets/defaultComic.jpg")} />
                </Pressable>
            )
        }
        return (
            <Pressable onPress={this._onPressItem}>
                <FastImage
                    url={`comic/${id}/${chapter}.bin`}
                    range={range}
                    style={{
                        width: windowWidth,
                        height: windowWidth * 2
                    }}
                />
            </Pressable>
        )
    }

    _onPressContent = (idx) => {
        this.currentIdx = idx;
        this._loadData()
    }

    _onPressItem = () => {
        this.setState({ showBtn: !this.state.showBtn })
    };

    jumpChapter = (bool) => {
        if (bool) {
            if (this.currentIdx == 0) {
                $toast('已经是第一话')
                return;
            }
            this.currentIdx -= 1;

        } else {
            if (this.currentIdx >= this.allContent.length - 1) {
                $toast('已经是最后一话')
                return;
            }
            this.currentIdx += 1;
        }
        this.setState({
            data: [],
            showBtn: false
        },()=>{
            this._loadData()
        });
    }

    render() {
        return (
            <>
                {this.state.data.length > 0 ?
                    <FlatList
                        ref={ref=>this.list=ref}
                        keyExtractor={(_, idx) => idx}
                        data={this.state.data}
                        style={{ flex: 1 }}
                        renderItem={this._renderItem}
                        heightForItem={item => {
                            return windowWidth * 2
                        }}
                        getItemLayout={(data, index) => {
                            return { length: windowWidth * 2, offset: windowWidth * 2 * index, index }
                        }}
                        numColumns={1}
                        initialNumToRender={5}
                        windowSize={5}
                        showsVerticalScrollIndicator={false}
                        extraData={this.state.data}
                    /> :
                    <TopLoading color={'#000'} />
                }

                {this.state.showBtn ? <View style={{
                    flexDirection: 'row',
                    marginBottom: px(5),
                    width: windowWidth,
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                    <TouchableOpacity onPress={() => {
                        this.jumpChapter(true);
                    }} >
                        <Text style={styles.textStyle} >上一章</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        this.modal.showModalByFadeIn(this.currentIdx);
                    }} >
                        <Text style={styles.textStyle} >目录</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        this.jumpChapter(false);
                    }} >
                        <Text style={styles.textStyle} >下一章</Text>
                    </TouchableOpacity>
                </View> : null}

                <ContentModal ref={ref => this.modal = ref} allContent={this.allContent} onPress={this._onPressContent} />
            </>

        )
    }
}

const styles = StyleSheet.create({
    textStyle: {
        lineHeight: px(88),
        textAlign: 'center',
        fontFamily: ''
    }
});

