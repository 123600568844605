import React, { Component } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { TabView, TabBar } from 'react-native-tab-view';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import { windowWidth } from '../common/Platform';
import TopLoading from '../components/TopLoading';
import px from '../utils/px';
import ActorView from './ActorView';
import TagPage from './TagPage';

export default class Actor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index: 0,
            routes: []
        }
    }

    componentDidMount = async () => {
        this.setState({ routes : $appData.actorIndex});
    };

    renderScene = ({ route }) => {
        return (
            <ActorView content={route.data} />
        )
    };

    renderTabBar = props => (
        <TabBar
            {...props}
            indicatorStyle={{ height:0 }}
            activeColor= {BaseStyles.tab_active_color}
            inactiveColor= {BaseStyles.tab_inactive_color}
            scrollEnabled
            tabStyle={{
                width: 'auto'
            }}
            style={{ backgroundColor: BaseStyles.tab_background_color }}
            renderLabel={({ route, focused, color }) => (
                <View style = {{ flexDirection:'row' ,alignItems:'center' ,alignSelf:'center'}}>
                    <Text style={{ color , fontSize: px(28) ,fontWeight: 'bold', fontFamily: "System"}} >
                    {route.title}
                    </Text>
                </View>
              )}
        />
    );

    render() {
        return (
            <View style={CommonStyles.body}>
                {
                    this.state.routes.length > 0 ? <TabView
                        lazy
                        lazyPreloadDistance={0}
                        renderTabBar={this.renderTabBar}
                        navigationState={this.state}
                        renderScene={this.renderScene}
                        onIndexChange={index => this.setState({ index })}
                        initialLayout={{ width: windowWidth ,height:0}}
                        renderLazyPlaceholder={()=> <TopLoading />}
                    /> : <TopLoading />
                }
            </View>
        );
    }
}