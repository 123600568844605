import React from 'react';
import { Text, View } from 'react-native';
import px from "../utils/px"
import navigate from './NavigatorUtil';
import CustomButton from './CustomButton';
import { BaseStyles } from './CommonStyle';
import FastImage from '../components/FastImage';

export default class ComicItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ref = React.createRef()
    }

    _onPressContent = () => {
        this.props.item.imgData = this.ref.current.getData();
        this.props.onPress ? this.props.onPress(this.props.item) : navigate('ComicDetail', { item: this.props.item });
    }

    render() {
        const { item: { id, name }, width = px(220), resizeMode = 'contain' } = this.props;
        const height = this.props.height || width * 14 / 10;

        return (
            <CustomButton
                onPress={() => {
                    this._onPressContent()
                }}
            >
                <View style={{ width, margin: px(10) }} >
                    <FastImage
                        comic={id}
                        style={{
                            width,
                            height,
                            borderRadius: 5
                        }}
                        resizeMode={resizeMode}
                        onRef={this.ref}
                    />
                    <Text numberOfLines={1} style={{ marginTop: px(5), fontSize: px(22), color: BaseStyles.name_color, fontWeight: 'bold', fontFamily: "System" }}>{name}</Text>
                </View>
            </CustomButton>
        )
    }
}