import React from 'react';
import { Text, View, Image, StyleSheet } from 'react-native';
import StringUtils from "../utils/StringUtils"
import FastImage from '../components/FastImage';
import px from "../utils/px"
import itemPlay from '../../assets/itemPlay.png';
import payIcon from '../../assets/payIcon.png';
import vipIcon from '../../assets/vipIcon.png';
import navigate from './NavigatorUtil';
import CustomButton from './CustomButton';
import { BaseStyles } from './CommonStyle';
import PrepareVideo from './PrepareVideo';

export default class VideoItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ref = React.createRef()
    }

    _onPressContent = () => {
        this.props.item.imgData = this.ref.current.getData();
        this.props.onPress ? this.props.onPress(this.props.item) : navigate('VideoDetail', { item: this.props.item });
    }
    _onScroll = () => {

        this.tv && this.tv.measure((x, y, width, height, pageX, pageY) => {

            if (pageX == null)
                return;

            let top = Math.floor(pageY - px(700));
            let bottom = Math.floor(pageY + height - px(780));

            if (y >= top && y <= bottom && this.preVideo) {
                this.preVideo.play()
            } else if (this.preVideo) {
                this.preVideo.stop()
            }
        })

    }
    render() {
        const width = this.props.width || px(352);
        const { id, name, tags, views, duration, imageURL , price=0} = this.props.item;
        return (
            <CustomButton
                onPress={() => {
                    this._onPressContent()
                }}
            >
                <View collapsable={false} ref={ref => this.tv = ref} style={{ width, margin: px(10) }}>
                    <View style={{
                        backgroundColor: '#1a1a1a', borderRadius: 2
                    }}>
                        <FastImage
                            index={this.props.index}
                            cover={id}
                            style={{
                                width,
                                height: width * 9 / 16,
                                borderRadius: 2
                            }}
                            onRef={this.ref}
                        />
                            
                        <Image style={{
                            width: px(100),
                            height: px(40),
                            position: 'absolute',
                            top: 0,
                            right: 2,
                        }} source={price >0 ? payIcon : vipIcon} />
                        
                        <View style={styles.bottomView}>
                            <View style={styles.bottomText}>
                                <Image style={{
                                    width: px(30),
                                    height: px(30)
                                }} source={itemPlay} />
                                <Text style={{ fontFamily: '', color: '#fff', fontSize: 14, }}> {views || 0}</Text>
                            </View>

                            <View style={styles.bottomText}>
                                <Text style={{ fontFamily: '', color: '#fff', fontSize: 14, }}> {StringUtils.timeFilter(duration || 0)}</Text>
                            </View>
                        </View>


                    </View>
                    <Text numberOfLines={1} style={{ fontSize: px(22), color: BaseStyles.name_color, fontWeight: 'bold', fontFamily: "System" }}>{name}</Text>

                    <View style={styles.tagContent}>
                        {
                            tags.map((tag, index) => {
                                return (
                                    <View style={styles.tagItem} key={index.toString()}>
                                        <Text style={styles.keyText} >{tag}</Text>
                                    </View>
                                )
                            })
                        }
                    </View>
                    <PrepareVideo onPress={this._onPressContent} ref={ref => this.preVideo = ref}
                        id={id}
                        style={{
                            width,
                            height: width * 9 / 16,
                            videoBackgroundColor: 'transparent'

                        }}
                    />
                </View>

            </CustomButton>
        )
    }
}


const styles = StyleSheet.create({
    bottomView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 5,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0 ,0.3)'
    },

    bottomText: {
        flexDirection: 'row',
        alignItems: 'center',
        height: px(40),
    },

    tagContent: {
        marginTop: px(5),
        flexDirection: 'row',
        overflow: 'hidden'
    },

    tagItem: {
        backgroundColor: BaseStyles.tagItem_bg_color,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
        margin: px(2),
        paddingHorizontal: px(5)
    },

    keyText: {
        color: '#999999',
        fontSize: px(22),
        fontFamily: 'System',
        fontWeight: 'bold'
    },
});