import React from 'react';
import {
    View,
    Text,
    Image,
    StyleSheet,
    ScrollView,
    TouchableWithoutFeedback,
    StatusBar,
    Pressable,
    FlatList
} from 'react-native';
import API from '../common/API';
import ComicItem from '../common/ComicItem';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import { windowWidth } from '../common/Platform';
import px from '../utils/px';
import axiosAssets, { recommend } from '../utils/ResourceHelper';

export default class ComicDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            item: {},
            comics: []
        };

        this.currentItem = this.props.route.params.item;
    }


    componentDidMount = async () => {
        this.loadData(this.currentItem);
        const { data: comics } = await recommend({ tags: this.currentItem.tags, type: 'comic' });
        this.setState({ comics })
    };

    componentWillUnmount() {
        $cld()
    }

    loadData = async (item) => {
        $ld()
        try {
            const { data } = await axiosAssets.get(`comic/${item.id}/index.bin`, { assetsType: 'json/text' });
            this.setState({
                data,
                item
            }, () => {
                $cld()
            });
        } catch (err) {
            $cld()
        }

    }

    renderChapters = (indexes) => {
        return (
            <View style={{ height: px(200) }}>

                <ScrollView>

                    {
                        indexes.map((item, idx) => {
                            return (
                                <Pressable key={idx.toString()} onPress={() => {
                                    this.props.navigation.navigate("ComicPlay", { allContent: indexes, idx, id: this.currentItem.id });
                                }} style={{
                                    width: windowWidth,
                                    padding: px(20),
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <Image
                                        resizeMode={'cover'}
                                        source={{ uri: this.state.item.imgData }}
                                        style={{
                                            width: px(150),
                                            height: px(100),
                                            borderRadius: 5
                                        }}
                                    />
                                    <Text style={{ marginLeft: px(20), fontFamily: '', fontSize: px(26), fontWeight: 'bold', color: BaseStyles.name_color }}>第{item?.chapter_num}话</Text>
                                    <Text numberOfLines={1} style={{ marginLeft: px(20), width: px(250), fontFamily: '', fontSize: px(26), fontWeight: 'bold', color: BaseStyles.name_color }}>{item?.chapter_name}</Text>
                                    <View style={{
                                        position: 'absolute',
                                        right: px(20),
                                        width: px(125),
                                        height: px(48),
                                        borderRadius: 12,
                                        backgroundColor: "#ff4848",
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}><Text style={{ fontFamily: '', fontWeight: 'bold', color: "#fff", fontSize: px(28) }}>观看</Text></View>
                                </Pressable>
                            )
                        })
                    }


                </ScrollView>
            </View>


        )

    }

    onClickRecommend = item => {
        this.currentItem = item;
        this.loadData(item)
    };

    renderItem = ({ item }) => {
        item.id = item.id.toUpperCase();
        return (
            <ComicItem item={item} onPress={this.onClickRecommend} width={px(220)} />
        )
    }

    render() {
        const { item: { imgData, desc, tags = [], views, name }} = this.state;
        let indexes = this.state.data?.indexes || [];
        return (
            <View style={CommonStyles.body}>
                <StatusBar translucent={true} backgroundColor='transparent' />
                <View>
                    <Image
                        style={styles.bgContainer}
                        source={{ uri: imgData }}
                        resizeMode='cover'
                    />
                    <Pressable onPress={() => { this.props.navigation.goBack() }} style={{ position: 'absolute', left: 0, top: 0, paddingHorizontal: px(30), paddingTop: px(80), paddingBottom: px(30) }}>
                        <Image
                            style={{ width: px(40), height: px(40) }}
                            source={require("../../assets/btn_back_default.png")}
                        />
                    </Pressable>
                    <View style={{ height: px(320), padding: px(10), flexDirection: 'row', marginTop: px(150) }}>
                        <Image
                            resizeMode={'contain'}
                            source={{ uri: imgData }}
                            style={{
                                width: px(217),
                                height: px(289),
                                borderRadius: 5
                            }}
                        />
                        <View style={{ flex: 1, paddingHorizontal: px(10) }} >
                            <Text allowFontScaling={true} numberOfLines={2} style={{ fontSize: px(30), color: BaseStyles.name_color, fontWeight: 'bold' }}>{name}</Text>
                            <View style={{ flex: 1, paddingVertical: px(10) }}>
                                <View style={{
                                    width: px(114),
                                    height: px(40),
                                    borderRadius: 9,
                                    backgroundColor: "#ffe7e7",
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Text style={{
                                        fontSize: px(20),
                                        color: "#ff592d"
                                    }}>更新{indexes.length}话</Text>
                                </View>
                                <ScrollView>

                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: px(20) }}>

                                        {
                                            tags.map((tag, index) => {
                                                return (
                                                    <View key={index} style={{
                                                        marginRight: px(10),
                                                        marginBottom: px(10),
                                                        paddingVertical: px(5),
                                                        paddingHorizontal: px(10),
                                                        borderRadius: 2,
                                                        backgroundColor: "#ff4848",
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Text style={{ color: '#ffffff', fontWeight: "bold", fontSize: px(18) }}>{tag}</Text>
                                                    </View>
                                                )
                                            })
                                        }
                                    </View>
                                    <Text style={{ color: BaseStyles.name_color, fontSize: px(22) }}>{desc}</Text>

                                </ScrollView>


                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ width: px(692), height: px(50), flexDirection: 'row', justifyContent: 'space-between', alignSelf: "center", marginTop: px(30) }}>
                    <Text style={{ color: BaseStyles.name_color, fontFamily: 'System', fontSize: px(34), fontWeight: 'bold' }}>目录</Text>
                    <TouchableWithoutFeedback onPress={() => {
                        this.setState(indexes.reverse())
                    }}>
                        <Text style={{ color: BaseStyles.name_color, fontFamily: 'System', fontSize: px(26), fontWeight: 'bold' }}>倒序</Text>
                    </TouchableWithoutFeedback>
                </View>
                {this.renderChapters(indexes)}

                <Text style={{ color: '#fff', lineHeight: 44, marginLeft: px(20) }}>推荐列表</Text>
                <FlatList
                    data={this.state.comics}
                    style={{ flex: 1 }}
                    contentContainerStyle={{ alignItems: 'center' }}
                    keyExtractor={item => item.id}
                    renderItem={this.renderItem}
                    numColumns={3}
                    showsVerticalScrollIndicator={false}
                />

            </View>
        )
    }
}



const styles = StyleSheet.create({

    bgContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        opacity: 0.3,
        width: windowWidth
    }
})