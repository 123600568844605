import React from 'react'
import { Text, View, Image, Pressable, TouchableOpacity, ScrollView } from 'react-native';
import px from "../utils/px"
import VideoItem from '../common/VideoItem'
import API from '../common/API';
import navigate from '../common/NavigatorUtil';
import { BaseStyles } from '../common/CommonStyle';
import { windowWidth } from '../common/Platform';
import FastImage from '../components/FastImage';

export default class PlayListView extends React.PureComponent {
  
    acMore = () => {
        navigate("Result", { tag: this.props.tag });
    }

    render() {
        const { data , image, height, width ,title} = this.props.tag;

        return (
            <View style={{ marginBottom: px(30)}}>
                <View>

                <FastImage
                    url={image}
                    style={{
                        width: windowWidth,
                        height: (height / width) * windowWidth,
                        marginBottom: px(10),
                        opacity : 0.5
                    }}
                    resizeMode={'cover'}
                />
                <Text numberOfLines={1} style={{ position:'absolute',right:px(30), bottom:px(30), backgroundColor:'rgba(255,255,255,0.3)', fontSize: px(70), fontFamily: 'System', fontWeight: 'bold', color: BaseStyles.tag_title_color }}>{title}</Text>
                </View>

                <ScrollView 
                    horizontal={true}    
                    showsHorizontalScrollIndicator={false}
                >
                    <View style={{ flexDirection: 'row'}}>
                        {
                            data.map((item) => {
                                return (
                                    <VideoItem key={item.id} item={item} width={windowWidth/2}/>
                                )
                            })
                        }
                         <Pressable onPress={this.acMore}
                            style={{ paddingHorizontal: px(30) , justifyContent:'center'}}>
                                <Text style={{ width:px(26) , fontSize: px(26), fontFamily: 'System', color: '#fff' }}>加载更多</Text>
                        </Pressable>
                    </View>
                </ScrollView>

            </View>
        )
    }
}