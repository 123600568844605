import React, { useImperativeHandle } from 'react'
import { Image } from 'react-native'
import { makeUseAxios } from 'axios-hooks';
import axiosAssets from '../utils/ResourceHelper';
const useAxios = makeUseAxios({ axios: axiosAssets });
function FastImage({ cover, comic, url , range, style, resizeMode ,onRef}) {

    const [{ data }, _] = useAxios({cover, comic, url, range});

    useImperativeHandle(onRef, () => {
        // 需要将暴露的接口返回出去
        return {
            getData
        };
    });

    const getData = () => {
        return data;
    }

    return (
        <Image
            source={{ uri: data }}
            resizeMode={resizeMode || 'contain'}
            style={style}
        />
    )
}
export default React.memo(FastImage)
