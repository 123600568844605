import * as React from 'react';
import {
    Pressable,
    Text,
    View
} from 'react-native';
import Carousel, { Pagination } from 'react-native-x2-carousel';
import px from '../utils/px';
import { windowWidth } from '../common/Platform';
import StringUtils from '../utils/StringUtils';
import FastImage from './FastImage';

export default class CarouselList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        }
    }

    _renderItem = (item,index) => (
        <Pressable
            key={item.key || index}
            onPress={() => StringUtils.click(item.url)}
        >
            <FastImage
                url={item.image}
                style={{
                    width: windowWidth,
                    height: (windowWidth * item.height) / item.width
                }} 
                resizeMode={'cover'}
            />
            <View style={{ justifyContent:'center', position: "absolute", bottom: 0, left:0, right:0,backgroundColor : 'rgba(0, 0, 0 ,0.3)'}}>
                <Text style={{  fontFamily: '', left: px(10), fontSize: px(30), color: "#fff" }}>{item.title}</Text>
            </View>

        </Pressable>
    )


    render() {
        return <Carousel
            layout={"default"}
            pagination={Pagination}
            data={this.props.data}
            sliderWidth={windowWidth}
            itemWidth={windowWidth}
            autoplay={true}
            autoplayDelay={100}
            loop={true}
            renderItem={this._renderItem}
            onSnapToItem={index => this.setState({ activeIndex: index })} />

    }
}