import { Component, PureComponent } from "react";
import { FlatList, Pressable, View } from "react-native";
import { CommonStyles } from '../common/CommonStyle';
import ListFooter from "../common/ListFooter";
import { windowWidth } from "../common/Platform";
import VideoItem from "../common/VideoItem";
import CarouselList from "../components/CarouselList";
import FastImage from "../components/FastImage";
import px from "../utils/px";
import { assets } from "../utils/ResourceHelper";
import StringUtils from "../utils/StringUtils";

export default class VideoNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            showFoot: 0,
            isRefreshing: false
        }

        this.totalPages = 0;
        this.currPage = 1;
        this.videoRefs = [];
        this.moveOffset = 0;
    }


    componentDidMount() {
        this._loadData()
    }

    _loadData = async () => {
        const { data, totalPages } = await assets({ type: 'video', key: this.props.tag.source, page: this.currPage });
        this.setState({
            data: this.state.data.concat(data),
            showFoot: totalPages > this.currPage ? 0 : 1,
            isRefreshing: false
        });
    }

    _renderItem = ({ item, index }) => {
        if (index % 10 == 1) {
            // const banner = this.props.tag.banner[Math.floor(index / 11) % this.props.tag.banner.length];
            if(StringUtils.isEmpty(this.props.tag.banner)){
                return null;
            }
            return (
                <CarouselList data={this.props.tag.banner} />
                // <Pressable onPress={() => {
                //     StringUtils.click(banner.url)
                // }}>
                //     <FastImage
                //         url={banner.image}
                //         style={{
                //             width: windowWidth,
                //             height: (banner.height / banner.width) * windowWidth,
                //             marginBottom: px(10)
                //         }}
                //     />
                // </Pressable>
            )
        }
        return (
            <VideoItem ref={view => this.videoRefs.push(view)} index={index} item={item} width={windowWidth} />
        )

    };


    /**标签下拉刷新 */
    _onRefresh = () => {
        if (!this.state.isRefreshing) {
            this.setState({
                isRefreshing: false,
            })
        }
    }
    /**标签上滑刷新 */
    _onEndReached = () => {
        if (this.state.showFoot != 0) {
            return;
        }
        this.currPage++;
        this.setState({ showFoot: 2 }, () => {
            this._loadData();
        });

    };

    render() {
        return (
            <View style={CommonStyles.body}>
                <FlatList
                    ref={ref => (this._list = ref)}
                    keyExtractor={item => item.id}
                    data={this.state.data}
                    style={{ flex: 1 }}
                    contentContainerStyle={{ alignItems: 'center' }}
                    renderItem={this._renderItem}
                    onScroll={(e) => {
                        const { contentOffset: { y }, layoutMeasurement: viewSize } = e.nativeEvent;
                        if (Math.abs(y - this.moveOffset) >= 10) {
                            this.moveOffset = y;
                            this.videoRefs.forEach(i => { i && i._onScroll(); });
                        }
                    }}
                    initialNumToRender={4}
                    windowSize={4}
                    numColumns={1}
                    refreshing={this.state.isRefreshing}
                    showsVerticalScrollIndicator={false}
                    ListFooterComponent={() => <ListFooter showFoot={this.state.showFoot} page={this.currPage} />}
                    onRefresh={this._onRefresh}
                    onEndReached={this._onEndReached}
                    onEndReachedThreshold={0.1}
                />
            </View>
        )
    }
}