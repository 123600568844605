import React from 'react';
import {ActivityIndicator, StyleSheet, Text, View,} from 'react-native';

export default class TopLoading extends React.PureComponent {

    static defaultProps = {
        data: [],
        text: '正在加载',
        color : "#fff"
    }

    constructor(props) {
        super(props);
    }

    render() {
        const {data , text , color} = this.props;
        if(data.length > 0)
            return null;
        return (
            <View style={styles.footer}>
                <ActivityIndicator size="small" color={color}/>
                <Text style={[styles.loadingText,{color: color}]}>{text}</Text>
            </View>
        )
    }
}

const styles = StyleSheet.create(
    {
        footer: {
            alignItems: 'center', flexDirection: 'row', justifyContent: 'center', height: 30,
        },
        loadingText: { fontSize: 14, marginLeft: 5,},
    });


