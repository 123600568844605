import React from 'react'
import { Text, View , ScrollView } from 'react-native';
import px from "../utils/px"
import RankVideoItem from '../common/RankVideoItem'
import RankComicItem from '../common/RankComicItem';
import { BaseStyles } from '../common/CommonStyle';
import RankActorItem from '../common/RankActorItem';

export default class Rank extends React.PureComponent {
    render() {
        return (
            <>
                <Text style={{ marginLeft: px(10),fontSize: px(30), fontFamily: 'System', fontWeight: 'bold', color: BaseStyles.name_color,marginVertical:px(20) }}>{this.props.tag.title}</Text>
                <ScrollView 
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                >
                    {
                        this.props.tag.data.map(list => {
                            return (
                                <View key={list.key} style={{  backgroundColor:'#262626',marginLeft: px(10), marginBottom:px(20), paddingVertical:px(20),paddingHorizontal:px(10),borderRadius:5}}>
                                    <Text style={{ fontSize: px(26), fontFamily: 'System',  color: BaseStyles.name_color }}>{list.title}</Text>
                                    {
                                        list.data.map(item=>{
                                            switch(list.type){
                                                case "VIDEO":
                                                    return <RankVideoItem key={item.key} item={item} width={px(200)} />
                                                case "COMIC":     
                                                    return <RankComicItem key={item.key} item={item} width={px(120)}/>
                                                case "ACTOR":
                                                    return <RankActorItem key={item.key} item={item} width={px(113)} />
                                                default:
                                                    return <View />
                                            }
                                        })
                                    }
                                </View>
                            )
                            
                            
                            
                        })
                    }
                </ScrollView>
            </>
        )
    }
}