import React from "react";
import {
    Image,
    StyleSheet,
    ScrollView,
    Text,
    View,
    RefreshControl,
    ImageBackground,
    Pressable,
    Linking,
} from "react-native";
import { axiosAPI } from "../common/API";
import { BaseStyles, CommonStyles } from "../common/CommonStyle";
import { windowWidth } from "../common/Platform";
import px from "../utils/px";
import StringUtils from "../utils/StringUtils";
import * as Device from 'expo-device';
import FastImage from "../components/FastImage";
import { getItem, removeItem } from "../utils/Storage";
import CarouselList from "../components/CarouselList";

export default class Mine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshing: false,
            hasTokenInfo:false,
            data: {
                name: ''
            },
        };
    }

    async componentDidMount() {
        this.viewDidAppear = this.props.navigation.addListener(
            'focus',
            this._tipReg
        )

    }

    _tipReg = async ()=>{
        let tokenInfo = await getItem('TOKENINFO');
        if (StringUtils.isEmpty(tokenInfo)) {
            this.setState({hasTokenInfo:false})
        }else{
            this.setState({ data: $infoData });
            this.setState({hasTokenInfo:true});

            if(!$infoData.hasBind){
                $box({
                    text: '当前处于游客模式,请一定要绑定手机或者邮箱,否则高概率会丢失您的会员账号',
                    cancelTip: '残忍拒绝',
                    confirmTip: '立即前往',
                    callback: ()=>{
                        this.jumpToPage('Bind')
                    }
                })
            }
        }
    }

    logOut = ()=>{
        $box({
            text: '确定要退出登录',
            cancelTip: '取消',
            confirmTip: '确定',
            callback: async () => {
                await removeItem('TOKENINFO');
                this.setState({hasTokenInfo:false})
            }
        })
        
    }

    _loadData = async ()=>{
        await axiosAPI.post(`info`, {place:'Mine'});
        this._tipReg()
    }

    componentWillUnmount() {
        this.viewDidAppear && this.viewDidAppear();
    }

    async jumpToPage(routeName, params = null) {
        let tokenInfo = await getItem('TOKENINFO');
        if (StringUtils.isEmpty(tokenInfo)) {
            this.props.navigation.navigate('Login',params)
        }else{
            this.props.navigation.navigate(routeName, params)
        }

    }

    renderBanner(){
        if(StringUtils.isEmpty($appConfig.carousel_mine)){
            return null
        }else{
            let arr = [];
            $appConfig.carousel_mine.forEach(e => {
                arr.push(JSON.parse(e))
            });
            return <CarouselList data={arr} />
            // const { url, image, height = 80, width = 480 } = $appConfig.banner_mine;
            // return (
            //     <Pressable style={{marginVertical:px(10)}} onPress={() => StringUtils.click(url)}>
            //         <FastImage
            //             // url={"jpg/08d77955220726fbcee6a00a042d74ec.bin"}
            //             url={image}
            //             style={{
            //                 width: windowWidth,
            //                 height: (height / width) * windowWidth,
            //                 marginBottom: px(10)
            //             }}
            //             resizeMode={'cover'}
            //         />
            //     </Pressable>
            // )
        }
    }

    render() {

        const { id , name, wallet, avatar, validity } = this.state.data;

        let { isVIP, vipDate } = StringUtils.getVipTime(validity);
        return (
            <View style={CommonStyles.body}>
                <ScrollView
                    refreshControl={
                        <RefreshControl
                            refreshing={this.state.refreshing}
                            onRefresh={this._loadData}
                            showsVerticalScrollIndicator={false}
                        />
                    }
                >
                    {
                        this.state.hasTokenInfo ? 
                        <ImageBackground source={require("../../assets/my/topBg.jpg")} style={{ width: windowWidth, height: px(450) }} >
                            <View style={{ flex: 1, alignItems: "center" }} >
                                <Image source={{ uri: avatar }} style={{ borderRadius: 50, width: px(160), height: px(160), marginTop: px(80) }} />
                                <Text style={{ fontWeight: 'bold', fontFamily: '', fontSize: px(30), color: '#fff', marginVertical: px(5) }} >{name}</Text>
                                <Text style={{ fontSize: px(22), fontWeight: "bold", fontFamily: "System", color: '#fff' ,marginBottom:px(5)}}>ID: {id}</Text>

                                {isVIP ?

                                    <Text style={{ fontWeight: 'bold', fontFamily: '', fontSize: px(30), color: '#FF9900', marginRight: px(18) }}>
                                        会员{vipDate}到期
                                    </Text>
                                    :
                                    <Text onPress={() => { this.jumpToPage("Charge") }} style={{ padding: px(10), borderRadius: 15, borderWidth: 1, borderColor: '#cccccc', color: '#cccccc', marginRight: px(18) }}>升级为会员获取更多特权
                                    </Text>
                                }
                            </View>
                            <Text onPress={this._loadData} style={{ position:'absolute' , right:px(20), top:px(20), padding: px(10), borderRadius: 15, borderWidth: 1, borderColor: '#cccccc', color: '#cccccc' }}>刷新</Text>

                        </ImageBackground> :

                        <ImageBackground source={require("../../assets/my/topBg.jpg")} style={{ width: windowWidth, height: px(450) }} >
                            <View style={{ flex: 1, alignItems: "center" , justifyContent:'center'}} >
                                <Text onPress={() => { this.jumpToPage("Login") }} style={{ fontSize:px(30),padding: px(10), borderRadius: 15, borderWidth: 1, borderColor: '#cccccc', color: '#cccccc', marginRight: px(18) }}>登录/注册
                                </Text>
                            </View>
                        </ImageBackground>
                    }
                    {this.renderBanner()}
                    <View style={{ flex: 1, margin: px(20), paddingHorizontal: px(10) }} >
                        <Pressable onPress={() => {
                            this.jumpToPage("Charge")
                        }} style={styles.btnStyle} >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Image style={styles.imgStyle} source={require("../../assets/my/qianbao.png")} />
                                <Text style={styles.btnText} >钻石余额</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ marginRight: px(40), fontFamily: '', fontSize: px(30), color: BaseStyles.name_color }} >{wallet}</Text>
                                <Image style={{ width: px(30), height: px(30), }} source={require("../../assets/rightArrow.png")} />
                            </View>
                        </Pressable>
                        {/* <Pressable onPress={() => {
                            this.jumpToPage("Share")
                        }} style={styles.btnStyle} >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Image style={styles.imgStyle} source={require("../../assets/my/fenxiang.png")} />
                                <Text style={styles.btnText} >分享有奖</Text>
                            </View>
                            <Image style={{ width: px(30), height: px(30), }} source={require("../../assets/rightArrow.png")} />
                        </Pressable> */}
                        <Pressable onPress={() => {
                            this.jumpToPage('History')
                        }} style={styles.btnStyle} >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Image style={styles.imgStyle} source={require("../../assets/my/lishi.png")} />
                                <Text style={styles.btnText} >观看历史</Text>
                            </View>
                            <Image style={{ width: px(30), height: px(30), }} source={require("../../assets/rightArrow.png")} />
                        </Pressable>
                        <Pressable onPress={() => {
                            this.jumpToPage('PayVideo')
                        }} style={styles.btnStyle} >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Image style={styles.imgStyle} source={require("../../assets/my/lishi.png")} />
                                <Text style={styles.btnText} >已购视频</Text>
                            </View>
                            <Image style={{ width: px(30), height: px(30), }} source={require("../../assets/rightArrow.png")} />
                        </Pressable>
                        <Pressable onPress={() => {
                            this.jumpToPage('Favorite')
                        }} style={styles.btnStyle} >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Image style={styles.imgStyle} source={require("../../assets/my/shoucang.png")} />
                                <Text style={styles.btnText} >收藏详情</Text>
                            </View>
                            <Image style={{ width: px(30), height: px(30), }} source={require("../../assets/rightArrow.png")} />
                        </Pressable>
                        <Pressable onPress={() => {
                            this.logOut()
                        }} style={styles.btnStyle} >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Image style={styles.imgStyle} source={require("../../assets/my/shoucang.png")} />
                                <Text style={styles.btnText} >退出登录</Text>
                            </View>
                            <Image style={{ width: px(30), height: px(30), }} source={require("../../assets/rightArrow.png")} />
                        </Pressable>
                       {
                           $appConfig.service && $appConfig.service !='' ?  <Pressable onPress={() => {
                            //    console.log("Device.deviceName",Device,Device.deviceName,Device.osVersion,Device.modelName)
                                Linking.openURL(`${$appConfig.service}&metadata={"name":"${name}","tel":"${Device.osVersion}","addr":"${Device.modelName}","qq":"${id}"}`)
                            }} style={styles.btnStyle} >
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image style={styles.imgStyle} source={require("../../assets/my/kefu.png")} />
                                    <Text style={styles.btnText} >联系客服</Text>
                                </View>
                                <Image style={{ width: px(30), height: px(30), }} source={require("../../assets/rightArrow.png")} />
                            </Pressable> : null
                       }

                    </View>
                </ScrollView>
            </View>
        )
    }
}
const styles = StyleSheet.create({
    btnStyle: {
        flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginVertical: px(20),

    },

    imgStyle: { width: px(55), height: px(55) },

    btnText: { fontFamily: '', fontSize: px(30), color: BaseStyles.name_color, marginLeft: px(10) }

});