import React from 'react'
import { Text, View, Image, Pressable, TouchableOpacity } from 'react-native';
import px from "../utils/px"
import VideoItem from '../common/VideoItem'
import API from '../common/API';
import navigate from '../common/NavigatorUtil';
import { BaseStyles } from '../common/CommonStyle';
import { assets } from '../utils/ResourceHelper';

export default class TagView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.tag
        }

        this.tag = this.props.tag;
        this.page = 0;
        this.videoItemRefs = [];
    }

    _loadData = async (page = 0) => {
        const data = await assets({ type: 'video', key: this.tag.source, page, pageSize: this.tag.pageSize});
        this.setState({ data });
    }
    _onScroll = () => {
        this.videoItemRefs.forEach(i => i && i._onScroll());
    }
    acMore = () => {
        navigate("Result", { tag: this.tag });
    }

    acChange = () => {
        this.page += 1;
        this._loadData(this.page)
    }
    render() {
        return (
            <View>
                <Pressable onPress={this.acMore}
                    style={{ padding: px(10), flexDirection: "row", justifyContent: "space-between", alignItems: 'center', marginTop: px(25) }}>
                    <Text numberOfLines={1} style={{ fontSize: px(30), fontFamily: 'System', fontWeight: 'bold', color: BaseStyles.tag_title_color }}>{this.tag.title}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text numberOfLines={1} style={{ marginRight: px(5), fontSize: px(26), fontFamily: 'System', color: '#fff' }}>更多</Text>
                        <Image style={{ width: px(30), height: px(30), marginRight: px(20) }} source={require("../../assets/rightArrow.png")}></Image>
                    </View>
                </Pressable>

                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {
                        this.state.data.data.map((item) => {
                            return (
                                <VideoItem key={item.id} item={item} width={px(352)} ref={ref => this.videoItemRefs.push(ref)} />
                            )
                        })
                    }
                </View>

                <TouchableOpacity onPress={this.acChange}>
                    <View style={{
                        marginTop: px(15),
                        marginBottom: px(20),
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingVertical: px(15),
                        borderRadius: 5,
                        backgroundColor: "#1a1a1a"
                    }}>
                        <Image style={{ width: px(30), height: px(30), marginRight: px(10) }} source={require("../../assets/change.png")}></Image>
                        <Text style={{
                            fontFamily: '',
                            color: '#999999',
                            fontSize: px(26),
                            fontWeight: 'bold'
                        }}>
                            换一换
                        </Text>
                    </View>


                </TouchableOpacity>
            </View>
        )
    }
}