import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Text, Modal, Pressable } from 'react-native';
import Events from '../utils/events';
import { isAndroid, windowHeight, windowWidth } from '../common/Platform';
import px from '../utils/px';
export default class Loading extends Component {

  constructor() {
    super();
    this.state = {
      modalVisible: false,
      text: null,
    }
  }
  componentDidMount() {
    Events.addListener('loading', this._loading)
  }

  _loading = ({ bl, text = null, callback = null }) => {
    this.callback = callback;
    this.setState({
      modalVisible: bl,
      text
    })
  }

  render() {
    return (
      <Modal
        transparent={true}
        visible={this.state.modalVisible}
        onRequestClose={() => { }}
      >
        <View style={styles.modalView}>
          <ActivityIndicator animating={this.state.modalVisible} size={'large'} color={'#9f9f9f'} />
          <Text style={{ fontSize: px(24), color: '#fff', fontWeight: 'bold' }}>{this.state.text || '正在加载中'}</Text>
          {
            this.callback ? <Pressable onPress={this.callback} style={{
              marginTop:px(10),
              padding: px(10),
              backgroundColor: 'red',
              borderRadius: 23,
            }} >

              <Text style={{ textAlign: 'center', lineHeight: px(40), fontSize: px(30), color: "#fff", fontWeight: "bold", fontFamily: "System" }} >
                点击重新加载</Text>
            </Pressable> : null
          }
        </View>
      </Modal>

    )

  }
}


const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0,0,0,0.4)',

  },
  loading: {
    position: 'absolute',
    width: windowWidth,
    height: windowHeight,
    backgroundColor: 'rgba(0,0,0,0.4)',
    alignItems: 'center',
    justifyContent: 'center'

  }
});


