/**
 * Created by Administrator on 2018/6/13.
 */

import React, { Component } from 'react';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity
} from 'react-native';

const LCCountDownButtonState = {
    LCCountDownButtonActive: 0,
    LCCountDownButtonDisable: 1,
}

// {id , startTime, deathCount}
var timeRecodes = [];  //根据id来记录LCCountDownButton的信息

export default class CodeButton extends Component {

    // 构造
    constructor(props) {
        super(props);
        // 初始状态
        this.state = {
            btnTitle: '默认',
            btnState: true,
        }
        this.shouldSetState = true;

    }

    static defaultProps = {
        id: "id",           //按钮的身份标识,同一个页面的按钮是同一个id
        beginText: "beginText",    //初始状态按钮title
        endText: "endText",      //读秒结束后按钮的title
        count: 10,             //总的计时数 单位是秒s
        pressAction: () => {
        },         //按下按钮的事件,但是触发倒数(startCountDown)需要你自己来调用
        changeWithCount: () => {
        },         //读秒变化的函数,该函数带有一个参数count,表示当前的剩余事件
        end: () => {
        },         //读秒完毕后的回调,读秒结束触发
        frameStyle: {},             //初始化的位置大小
        disableStyle: {},             //按钮禁用的时候样式                 (有默认,见底部styles)
        activeStyle: {},             //active情况下按钮样式              (有默认,见底部styles)
        disableTextStyle: {},             //按钮禁用的时候里面文字的样式        (有默认,见底部styles)
        activeTextStyle: {},             //active情况下按钮里面文字的样式      (有默认,见底部styles)
        disableViewStyle: {},
        activeViewStyle: {},
        startAuto: false
    }

    buttonState = LCCountDownButtonState.LCCountDownButtonActive;

    componentDidMount() {
        this.setState({
            btnTitle: this.props.beginText,
        });
        this.props.startAuto && this.startCountDown();

    }

    clearTime() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    componentWillUnmount() {
        this.shouldSetState = false;
        this.clearTime();
    }

    startCountDownWithCount(startTime) {
        this.buttonState = LCCountDownButtonState.LCCountDownButtonDisable;
        const { changeWithCount, endText, count, end } = this.props;
        this.startTime = startTime;
        this.interval = setInterval(() => {
            let detalTime = Math.round((Date.now() - this.startTime) / 1000);
            let content = changeWithCount(count - detalTime);
            if (detalTime >= count) {
                content = endText;
                this.clearTime();
                end && end();
                this.buttonState = LCCountDownButtonState.LCCountDownButtonActive;
            }
            if (this.shouldSetState) {
                this.setState({
                    btnTitle: content
                })
            }
        }, 1000)
    }

    recordButtonInfo() {
        const { id, count } = this.props;
        var hasRecord = false;
        for (var i = 0; i < timeRecodes.length; i++) {
            let obj = timeRecodes[i];
            if (obj.id == id) {
                obj.startTime = Date.now();
                hasRecord = true;
                break;
            }
        }
        if (!hasRecord) {
            let buttonInfo = {
                id: id,
                deathCount: count,
                startTime: Date.now()
            }
            timeRecodes.push(buttonInfo)
        }
    }

    //外界调用
    startCountDown() {
        this.startCountDownWithCount(Date.now());
        // this.recordButtonInfo();
    }

    disabledButton() {
        this.buttonState = LCCountDownButtonState.LCCountDownButtonDisable;
        this.setState({ btnState: false });
    }

    activeButton() {
        this.buttonState = LCCountDownButtonState.LCCountDownButtonActive;
        this.setState({ btnState: true });
    }

    buttonPressed = () => {
        const { pressAction } = this.props;
        pressAction && pressAction();
    }

    render() {
        let isDisable = this.buttonState == LCCountDownButtonState.LCCountDownButtonDisable;
        const { frameStyle, disableStyle, activeStyle, disableTextStyle, activeTextStyle, disableViewStyle, activeViewStyle }
            = this.props;
        return (
            <TouchableOpacity disabled={isDisable}
                onPress={this.buttonPressed}
            >
                <View style={[styles.buttonCommonStyle,
                isDisable ? styles.disableButtonStyle : styles.activeButtonStyle,
                isDisable ? disableStyle : activeStyle,
                isDisable ? disableViewStyle : activeViewStyle,
                    frameStyle]}>
                    <Text style={[
                        styles.txtCommonStyle,
                        isDisable ? styles.disableTxtStyle : styles.activeTxtStyle,
                        isDisable ? disableTextStyle : activeTextStyle
                    ]}>
                        {this.state.btnTitle}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    }


}

const styles = StyleSheet.create({

    buttonCommonStyle: {
        borderRadius: 3,
        paddingRight: 8,
        paddingLeft: 8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    //禁用时候的TouchableOpacity样式
    disableButtonStyle: {
        backgroundColor: 'red',
    },
    //可以点击时候的TouchableOpacity样式
    activeButtonStyle: {
        backgroundColor: 'green',
    },

    txtCommonStyle: {
        fontSize: 14,
    },
    //禁用时候的Text样式
    disableTxtStyle: {
        color: '#fff',
    },
    //可以点击时候的Text样式
    activeTxtStyle: {
        color: '#fff',
    }
});