import axios from "axios";
import { Buffer } from "buffer"
import any from 'promise.any';
import { Platform } from "react-native";
import { m3u8, resource, } from '../utils/ResourceHelper';
import { getItem, removeItem, setItem } from "../utils/Storage";
import RNRestart from 'react-native-restart';
import * as Device from 'expo-device';
import * as Application from "expo-application";
import baseInfo from '../baseInfo.json'
import QRCode from 'qrcode-generator';
import { aspect } from '../common/Platform'
import StringUtils from "../utils/StringUtils";
import navigate from "./NavigatorUtil";
axios.defaults.timeout = 15000;

let token;

export const axiosAPI = axios.create();

axiosAPI.interceptors.request.use(
  (config) => {
    if (token) {
      const auth = token ? `Bearer ${token}` : '';
      config.headers.common['Authorization'] = auth;
    }
    if (!config.data)
      config.data = {}
    config.data.platform = Platform.OS;

    return config;
  },
  (error) => Promise.resolve({ error: { error, message: '请求错误' } }),
);
axiosAPI.interceptors.response.use((response) => {
  if (response.data.info) {
    const prefix = $ASSETS_ENDPOINT.replace($appConfig.version, '');
    response.data.info.avatar = `${prefix}${response.data.info.avatar}`;
    $infoData.refresh(response.data.info);
  }
  return response;
}, (error) => {
  if (error.response?.status == 401) {
    removeItem('TOKENINFO').then(()=>{
      navigate('Login')
    });
  }
  const { message } = error.response ? error.response.data : { message: "网络请求错误" };
  return Promise.resolve({ error: { error, message } });
});

let connectSpeed = {
  index: 0,
  res: 0,
  api: 0
};
const INCLUDE = (arr1, arr2) => {
  return arr2.every(val => arr1.includes(val))
}
export default class API {
  static async init(loginInfo, stateChangeCallback, splashCallback = null) {
    stateChangeCallback('正在获取配置')
    const assetsKEY = Platform.OS == 'web' ? 'assets_web' : 'assets_native';
    const resKEY = Platform.OS == 'web' ? 'res_web' : 'res_native';
    let startTime = new Date().getTime();
    try {
      if(Platform.OS == 'web'){
        let {data} = await axios.get('baseConfig.json');
        $appConfig = data;
      }else{
        const { data: newConfig } = await any($appConfig.config.map(url => axios.get(url, { responseType: 'json' }).then(({ data }) => {
          if (INCLUDE(Object.keys(data), ['assets_web', 'res_web', 'assets_native', 'res_native', 'version'])) {
            return { data };
          }
          throw new Error('获取配置失败');
        })));//测速 获取index_endpoint置
        $appConfig = newConfig;
        setItem("APPCONFIG", newConfig);
      }
    } catch (ex) {
      stateChangeCallback(ex.message + ',config');
      return false;
    }

    // $appConfig.version = 'test20220505';
    if(!$appConfig.entry){
      $appConfig.entry = `https://desktop.ytplus.app/desktop`
    }
    try {
      stateChangeCallback('正在测试图片加速链路')
      $ASSETS_ENDPOINT = await any($appConfig[assetsKEY].map(url => axios.get(`${url}test.data`, { responseType: 'arraybuffer' }).then(({ data }) => {
        if (data?.byteLength == 10240) return url + $appConfig.version;
        throw new Error('图片加速测试失败');
      })));//测速 获取index_endpoint
    } catch (ex) {
      stateChangeCallback(ex.message + ',assets');
      return false;
    }
    try {
      const { mobile = false } = loginInfo;
      if (Platform.OS != 'web' || mobile) {
        $launch = $appConfig.launch
      }else{
        $launch = $appConfig.launch_h5
      }
      connectSpeed.index = new Date().getTime() - startTime;
      if(!StringUtils.isEmpty($launch?.enter)){
        $enter = $launch.enter
      }
      if (StringUtils.isEmpty($launch?.image)) {
        splashCallback(null);
      } else {
        resource($launch.image).then(({ data }) => {
          splashCallback(data);
        });
      }
      
      stateChangeCallback('正在测试接口加速链路')
      $API_ENDPOINT = await any($appConfig.api.map(url => axios.get(`${url}test.txt`).then(({ data }) => { //测速 获取index_endpoint
        if (data == 'success') return url;
        throw new Error('接口加速测试失败');
      })))
      axiosAPI.defaults.baseURL = $API_ENDPOINT;
      // axiosAPI.defaults.baseURL = 'http://192.168.1.100:61344/';
    } catch (ex) {
      stateChangeCallback(ex.message + ',api');
      return false;
    }
    try {
      connectSpeed.api = new Date().getTime() - startTime - connectSpeed.index;
      stateChangeCallback('正在测试影片加速链路')
      $RES_ENDPOINT = await any($appConfig[resKEY].map(url => axios.get(`${url}test.data`, { responseType: 'arraybuffer' }).then(({ data }) => { //测速 获取index_endpoint
        if (data?.byteLength == 10240) return url;
        throw new Error('影片加速测试失败');
      })))
      connectSpeed.res = new Date().getTime() - startTime - connectSpeed.index - connectSpeed.api;

      stateChangeCallback('正在加载数据')
      const connect_index = await API.Index(loginInfo);
      if (!connect_index) {
        stateChangeCallback('加载数据失败');
        return false;
      }
      $DEVICE_TYPE = await Device.getDeviceTypeAsync();
      stateChangeCallback('加载完成')
      // if()
      API.loadQRCode();
    } catch (ex) {
      stateChangeCallback(ex.message);
      return false;
    }
    try {
    } catch (ex) {
      console.log(ex);
    }
    return true;
  }

  static loadQRCode() {
    if (!$appConfig.entry || aspect < 0.75) {
      return;
    }
    try {
      const qr = QRCode(6, 'L');

      qr.addData(`${$appConfig.entry}?device=${$infoData.device}&proxy=${$infoData.proxy}`);
      qr.make();
      const imageSrc = qr.createDataURL();
      console.log(imageSrc);
      const images = document.getElementsByName('qrcode');
      images.forEach(image => {
        image.src = imageSrc;
        // image.style.visibility = 'visible'
      })
      const containers = document.getElementsByName('qrcode_container')
      containers.forEach(c=>{
        c.style.visibility = 'visible';
      })
    } catch (ex) {
      console.log(ex);
    }
    // setBase64Img();
  }
  static async loadConfig() {
    const { data } = await axios.get(`${$ASSETS_ENDPOINT}/index.bin`, { responseType: 'arraybuffer' });
    $appData = JSON.parse(xor(Buffer.from(data)).toString());
    $appData.videoIndex.forEach((r, i) => r.index = i);
  }

  static async loadVideoSearch(type) {
    if (type == 'comic')
      return $appData.comicSearch;
    return $appData.videoSearch;
  }

  static async Info(tokenInfo) {
    try {
      token = tokenInfo.token;
      await setItem('TOKENINFO', tokenInfo);
      await axiosAPI.post(`info`, { mobile: global.$mobile ,place:'Info'});
    } catch (err) {
      console.log(err)
    }
  }

  static async Index({ device, mobile = false, proxy, inviter = '' }) {
    if (baseInfo?.defaultProxy) {
      proxy = baseInfo.defaultProxy;
    }
    global.$mobile = mobile;
    try {
      // throw new Error(222);
      let tokenInfo = await getItem('TOKENINFO');
      if (tokenInfo) {
        if (new Date().getTime() < tokenInfo.timestamp) {
          token = tokenInfo.token;
        } else {
          await removeItem('TOKENINFO');
          if (Platform.OS != 'web') {
            RNRestart.Restart();
          }
        }
        await axiosAPI.post(`info`, { mobile ,place:'Index'});
      } 
      await API.loadConfig();
    } catch (err) {
      return false

    }
    return true
  }

  static async BUY(id) {
    // let start= new Date().getTime();
    let { data, error } = await axiosAPI.post(`/buy`, { id });
    if (error) {
      $toast(error.message)
      return false;
    }
    return true;
  }

  static async PLAY(id) {
    // let start= new Date().getTime();
    let { data: { token, price }, error } = await axiosAPI.post(`/view`, { id });
    if (error) {
      $toast(error.message)
      return;
    }
    if(!token){
      return null;
    }
    try {
      let url = await m3u8(token, id);
      return url;
    } catch (ex) {
      alert(ex);
    }
    return null;
  }
}

const xor = (buffer) => {
  for (let i = 0; i < buffer.length; i++) {
    buffer[i] = buffer[i] ^ 123;
  }
  return buffer;
}
