import React, { Component } from "react";
import { Modal, StyleSheet, Text, View} from "react-native";
import { isAndroid } from "../common/Platform";
import Events from "../utils/events";
import { WebView } from 'react-native-webview';

export default class CustomWebView extends Component {
  constructor() {
    super();
    this.state = {
      modalVisible: false,
      toastText: ''
    };
  }

  componentDidMount() {
    Events.addListener("webview", this._toast)
  }

  _toast = (text) => {
    this.setState({
      modalVisible: true,
      toastText: text
    })
  }


  render() {
    return (
      <>
       {
                isAndroid() ? <WebView
                  source={{uri: 'https://github.com/facebook/react-native'}}
                  style={{ flex:1}}
                />:null
              }
     </>
    );
  }
}