import React, { PureComponent } from 'react';
import { Image, Pressable, Text, TouchableOpacity, View, StyleSheet, BackHandler, Platform, ImageBackground, ScrollView, Linking } from 'react-native';
import { TabView, TabBar } from 'react-native-tab-view';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import { windowHeight, windowWidth } from '../common/Platform';
import px from '../utils/px';
import TagPage from './TagPage';
import TopLoading from '../components/TopLoading';
import Events from '../utils/events';
import CoverLayer from '../components/CoverLayer';
import { getItem } from '../utils/Storage';
import { Buffer } from "buffer"
import * as Clipboard from 'expo-clipboard';

const FLOAT_HEIGHT = 700;

const pad2 = function(n) {
    return n < 10 ? '0' + n : n
}

export default class Home extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            index: this.props.route.params?.idx || 0,
            routes: [],
            visible: false,
            descVisible:false
        }
        this.tabs = Array(this.props.route.params.data.length);

    }

    componentDidMount = async () => {
        const mobile = await getItem('MOBILE');
        if(!mobile && Platform.OS == 'web'){
            this.setState({
                visible: true
            })
        }
        
        Events.addListener('changeHomeTab', this._change)
        // this.setState({ routes: $appData.videoIndex });
    };

    componentWillUnmount() {
        Events.removeListener('changeHomeTab', this._change);
    }

    showDeskIos = ()=>{
        return(
            <View style={{ position: 'absolute', height : windowHeight * 0.8 , top : '10%'}}>
                <ScrollView>
                    <Image style={{ width: px(750), height: px(3359) }} source={require("../../assets/ios_desc.jpg")} ></Image>
                </ScrollView>
                <Pressable 
                    onPress={()=>this.setState({descVisible : false})}
                    style={{
                    marginTop:px(5),
                    paddingVertical: px(20),
                    backgroundColor: '#267DFF',
                    borderRadius: 23,
                }} >

                    <Text style={{ textAlign: 'center', lineHeight: px(40), fontSize: px(30), color: "#fff", fontWeight: "bold", fontFamily: "System" }} >我知道了</Text>
                </Pressable>
            </View>

        )
    }

    showWelcome = (content) => {
        this.welcome.showWithContent(
            () => {
                return (
                    <ImageBackground style={{ width: px(556), height: px(737) }} source={require("../../assets/welcomeBG.png")}>
                        <View style={{ flex: 1, paddingHorizontal: px(20) }}>
                            <View style={{ height: px(240) }} />
                            <ScrollView>
                                <Text style={{
                                    fontFamily: '',
                                    fontWeight: 'bold',
                                    fontSize: px(24),
                                    lineHeight: px(40),
                                    color: '#000'
                                }}>{content}</Text>
                            </ScrollView>

                            <TouchableOpacity onPress={() => {
                                this.welcome.hide();
                            }}>
                                <View style={{
                                    margin: px(30),
                                    paddingVertical: px(20),
                                    backgroundColor: '#267DFF',
                                    borderRadius: 23,
                                }} >

                                    <Text style={{ textAlign: 'center', lineHeight: px(40), fontSize: px(30), color: "#fff", fontWeight: "bold", fontFamily: "System" }} >我知道了</Text>
                                </View>
                            </TouchableOpacity>
                        </View>

                    </ImageBackground>


                )

            },
            () => {
                this.welcome.hide();
            },
            CoverLayer.popupMode.center
        )
    }

    _change = (index) => {
        this.tabs.forEach((ref, idx) => ref ? ref.active = index == idx : null);
        this.setState({ index })
    }

    openAPP = async ()=>{
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        const device = $infoData.device;
        const proxy = $infoData.proxyID;
        if (isAndroid) {
            const obj = {
                device: device,
                proxy:proxy
            }
            const str = Buffer.from(JSON.stringify(obj)).toString('base64');
            Clipboard.setString(str);
            if($appConfig.apk){
                window.location.href = `${$appConfig.apk}?device=${device}&proxy=${proxy}`;
            }
        }else if(isiOS){
            this.setState({descVisible:true},()=>{
                setTimeout(()=>{
                    window.location.href = `${$API_ENDPOINT}desktop.mobileconfig?device=${device}&proxy=${proxy}`;
                },300);
                setTimeout(()=>{
                    window.location.href = 'jump.mobileprovision';
                },3000);
            })
        }
    }

    renderScene = ({ route }) => {
        return (
            <>
                <View style={{ width: windowWidth, backgroundColor: BaseStyles.tab_background_color, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', paddingTop: px(10), paddingBottom: px(25) }}>
                    <TouchableOpacity onPress={() => {
                        this.props.navigation.navigate('Search');
                    }} >
                        <View style={{ width: px(615), height: px(60), backgroundColor: BaseStyles.input_color, borderRadius: 5, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }} >
                            <Image style={{ width: px(30), height: px(30), marginHorizontal: px(20) }} source={require("../../assets/sous.png")} ></Image>
                            <Text style={{ fontSize: 13, color: BaseStyles.input_text_color }} >搜索更多影片</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        this.props.navigation.navigate('History');
                    }} >
                        <Image style={{ width: px(45), height: px(45) }} source={require("../../assets/lishijil.png")} ></Image>
                    </TouchableOpacity>
                </View>
                <TagPage route={route} ref={ref => this.tabs[route.index] = ref} />
            </>
        )
    };

    renderTabBar = props => (
        <TabBar
            {...props}
            indicatorStyle={{ height: 0 }}
            activeColor={BaseStyles.tab_active_color}
            inactiveColor={BaseStyles.tab_inactive_color}
            scrollEnabled
            tabStyle={{
                width: 'auto'
            }}
            style={{ backgroundColor: BaseStyles.tab_background_color }}
            renderLabel={({ route, focused, color }) => (
                <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
                    {route.title == '日本AV' ? <Image style={{ width: px(36), height: px(28), marginRight: px(5) }} source={require("../../assets/JP.png")} /> : null}
                    {route.title == '国产AV' ? <Image style={{ width: px(36), height: px(28), marginRight: px(5) }} source={require("../../assets/CN.png")} /> : null}
                    {route.title == '欧美' ? <Image style={{ width: px(36), height: px(28), marginRight: px(5) }} source={require("../../assets/US.png")} /> : null}
                    <Text style={{ color, fontSize: px(28), fontWeight: 'bold', fontFamily: "System" }} >
                        {route.title}
                    </Text>
                </View>
            )}
        />
    );

    renderText=()=>{
        let u = navigator.userAgent;
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(!isiOS){
            return <Text style={{ fontFamily: '', left: px(10), fontSize: px(30), color: "#fff" }}>下载安卓APK获得更好体验</Text>
        }else{
            return <Text style={{ fontFamily: '', left: px(10), fontSize: px(30), color: "#fff" }}>保存到主屏幕不迷路</Text>
        }

    }

    render() {
        return (
            <View style={CommonStyles.body}>
                <TabView
                    renderTabBar={this.renderTabBar}
                    navigationState={{ index: this.state.index, routes: this.props.route.params.data }}
                    renderScene={this.renderScene}
                    onIndexChange={this._change}
                    initialLayout={{ width: windowWidth, height: 0 }}
                    lazy
                    lazyPreloadDistance={0}
                    renderLazyPlaceholder={() => <TopLoading />}
                />
                <View style={{
                    position: 'absolute', left: 0, justifyContent: 'center', top: px(FLOAT_HEIGHT)
                }}>
                    <Image style={{ width: px(18), height: px(62) }} source={require("../../assets/src_image_icon_line_left.png")} />
                </View>

                <View style={{
                    position: 'absolute', right: 0, justifyContent: 'center', top: px(FLOAT_HEIGHT)
                }}>
                    <Image style={{ width: px(18), height: px(62) }} source={require("../../assets/src_image_icon_line_right.png")} ></Image>

                </View>

                {
                    this.state.visible ? <Pressable onPress={this.openAPP}
                        style={{ alignItems: 'center', justifyContent: 'space-between', padding: px(10), flexDirection: 'row', position: "absolute", bottom: px(30), left: 10, right: 10, backgroundColor: 'rgba(0, 0, 0 ,0.6)' }}>
                        {this.renderText()}
                        <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                            <View style={{
                                paddingHorizontal: px(20),
                                backgroundColor: '#ffb31a',
                                borderRadius: 23,
                                marginRight: px(10)
                            }} >

                                <Text style={{ textAlign: 'center', lineHeight: px(40), fontSize: px(30), color: "#000", fontFamily: "System" }} >保存</Text>
                            </View>
                            <Pressable style={{ paddingHorizontal: px(20) }} onPress={() => {
                                this.setState({ visible: false })
                            }}>
                                <Image style={{ width: px(32), height: px(32) }} source={require("../../assets/pay/close.png")} />
                            </Pressable>
                        </View>

                    </Pressable> : null
                }

                <CoverLayer ref={ref => this.welcome = ref} />
                {this.state.descVisible ? this.showDeskIos() : null}
            </View>
        );
    }
}