import config from '../../web/baseConfig.json';
import lru from 'lru-cache';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';
import StringUtils from '../utils/StringUtils';

global.$paySource = 'VOD'
global.$appData = {}
global.$API_ENDPOINT = ''
global.$RES_ENDPOINT = ''
global.$ASSETS_ENDPOINT = ''
global.$infoData = {}
global.$hasOrder = false
global.$DEVICE_TYPE = 'android'
global.$mobile = false
global.$launch = {}
global.$enter = 'auto'
class UserData {
  constructor() {

  }
  refresh(data) {
    Object.assign(this, data);
  }
  get vip() {
    return StringUtils.getVipTime(this.validity).isVIP;
  }
  get views() {
    return { ...this.info.views, vip: this.vip };
  }
}
global.$infoData = new UserData();
global.$appConfig = config

// { max: 500
//   , length: function (n, key) { return n * 2 + key.length }
//   , dispose: function (key, n) { n.close() }
//   , maxAge: 1000 * 60 * 60 }
global.cache = new lru({
  max: 500,
  dispose: (value, key, reason) => {
    if (Platform.OS != 'web' && (typeof value === 'string' || value instanceof String) && value.indexOf('://') >= 0) {
      FileSystem.deleteAsync(value).catch(console.error);
    }
  },
  maxSize: 500,
  sizeCalculation: () => {
    return 1;
  },
  ttl: 1000 * 60 * 5,
  allowStale: false,
  updateAgeOnGet: true,
  updateAgeOnHas: true,
  updateRecencyOnHas: true,
})

global.$ASSETS_ENDPOINT = null;
