import React, {Component} from 'react';
import {ActivityIndicator, StyleSheet, Text, View,} from 'react-native';
import PropTypes from "prop-types";

export default class ListFooter extends Component {

    static propTypes = {
        showFoot: PropTypes.number,
        page: PropTypes.number,
    }
    static defaultProps = {
        showFoot: 0,
        page: 1,
    }

    constructor(props) {
        super(props);
    }

    render() {
        const {page, showFoot} = this.props;
        if (showFoot === 1 && page > 1) {
            return (
                <View style={styles.completedView}>
                    <View style={styles.line}/>
                    <Text style={styles.completedText}>
                        已经到底
                    </Text>
                    <View style={styles.line}/>
                </View>
            );
        } else if (showFoot === 2) {
            return (
                <View style={styles.footer}>
                    <ActivityIndicator size="small" color="#fff"/>
                    <Text style={styles.loadingText}>正在加载更多数据...</Text>
                </View>
            );
        } else {
            return null;
        }
    }
}

const styles = StyleSheet.create(
    {
        completedView: {height: 30, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'},
        completedText: {color: '#fff', fontSize: 12,},
        footer: {
            alignItems: 'center', flexDirection: 'row', justifyContent: 'center', height: 30,
        },
        loadingText: {color: '#fff', fontSize: 14, marginLeft: 5,},
        line: {height: 1, backgroundColor: '#eeeeee', flex: 1, marginHorizontal: 15}
    });


