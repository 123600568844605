import React from 'react';
import { FlatList, View, ScrollView, Text, Pressable } from 'react-native';
import CarouselList from '../components/CarouselList';
import { windowWidth } from '../common/Platform';
import px from '../utils/px';
import ComicView from './ComicView';
import TagView from './TagView';
import Rank from './Rank';
import { BaseStyles } from '../common/CommonStyle';
import StringUtils from '../utils/StringUtils';
import FastImage from '../components/FastImage';
import PlayListView from './PlayListView';
import ComicNew from './ComicNew';
import VideoNew from './VideoNew';
import CustomButton from '../common/CustomButton';
import navigate from '../common/NavigatorUtil';

export default class TagPage extends React.Component {
    constructor(props) {
        super(props)
        this.tagViewRefs = [];
        this.moveOffset = 0;

        this.state = {
            data: [],
            isRefreshing: false
        }
        this.active = false;
    }
    shouldComponentUpdate() {
        return this.active;
    }


    renderItem = ({ item }) => {
        switch (item.type) {
            case "COMIC":
            case "CUSTOM_COMIC":
                return <ComicView tag={item} />
            case "VIP":
            case "VIDEO":
                return <TagView tag={item} ref={view => this.tagViewRefs.push(view)} />
            case "PLAYLIST":
                return <PlayListView tag={item} />
            case "LEADER":
                return <Rank tag={item} />
            case "NAVIGATION":
                return (
                    <View style={{
                        flexDirection: "row",
                        justifyContent: 'space-between',
                        flexWrap: "wrap",
                        marginBottom: px(10)
                    }} >
                        {
                            item.data.map(({ key, url, image, radius = 10, style, title, margin = 20 }) => {
                                return (
                                    <Pressable key={key} onPress={() => StringUtils.click(url)}>
                                        <FastImage
                                            url={image}
                                            style={{
                                                width: windowWidth / (item.column + 2),
                                                height: windowWidth / (item.column + 2),
                                                margin: px(margin),
                                                borderRadius: radius
                                            }}
                                        />
                                        <Text style={[{ fontFamily: '', fontSize: 13, alignSelf: 'center', color: BaseStyles.name_color }, style]}>{title}</Text>
                                    </Pressable>
                                )
                            })
                        }
                    </View>
                )
            case "BANNER":
                const { url, image, height = 80, width = 480 } = item.data.randomMember();
                return (
                    <Pressable onPress={() => StringUtils.click(url)}>
                        <FastImage
                            url={image}
                            style={{
                                width: windowWidth,
                                height: (height / width) * windowWidth,
                                marginBottom: px(10)
                            }}
                            resizeMode={'cover'}
                        />
                    </Pressable>
                )
            case "SWIPER":
                return (
                    <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        style={{ marginBottom: px(10) }}
                    >
                        {
                            item.data.map(({ key, url, image, height = 200, width = 200, radius = 10, style, title, margin = 15 }) => {
                                return (
                                    <Pressable key={key} onPress={() => StringUtils.click(url)}>
                                        <FastImage
                                            url={image}
                                            style={{
                                                width: px(width),
                                                height: px(height),
                                                margin: px(margin),
                                                borderRadius: radius
                                            }}
                                        />
                                        <Text style={[{ fontFamily: '', fontSize: 13, alignSelf: 'center', color: BaseStyles.name_color }, style]}>{title}</Text>
                                    </Pressable>
                                )
                            })
                        }
                    </ScrollView>
                )
            case "SWIPER_ACTOR":
                return (
                    <>
                        <Text style={[{ fontFamily: '', fontSize: px(36) , marginLeft:px(10), color: BaseStyles.name_color }]}>{item.title}</Text>
                        <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            style={{ marginBottom: px(10) }}
                        >
                            {
                                item.data.map((it,index) => {
                                    return (
                                        <Pressable onPress={() => { navigate('ActorDetail', { item: it })}} key={it.id} style={{ margin: px(10)}} >
                                            <FastImage
                                                url={it.avatar}
                                                style={{
                                                    width: px(220),
                                                    height: px(220),
                                                    borderRadius:5,
                        
                                                }}
                                                onRef={this.ref}
                                            />
                                            <Text numberOfLines={1} style={{ marginTop:px(5),fontSize: px(22), textAlign:'center',color: BaseStyles.name_color, fontWeight: 'bold', fontFamily: "System" }}>{it.nameCN}</Text>
                                        </Pressable>
                                    )
                                })
                            }
                        </ScrollView>
                    </>
                )
            case "CAROUSEL":
                return <CarouselList data={item.data} />
            default:
                return <View />
        }
    }

    _onRefresh = () => {
        if (!this.state.isRefreshing) {
            this.setState({
                isRefreshing: false,
            })
        }
    }

    renderContent = () => {
        switch (this.props.route.type) {
            case "SINGLE_LIST_VIDEO":
                return <VideoNew tag={this.props.route} />
            case "SINGLE_LIST_COMIC":
                return <ComicNew tag={this.props.route} />
            default:
                return (
                    <FlatList
                        data={this.props.route.data}
                        style={{ flex: 1 }}
                        renderItem={this.renderItem}
                        onScroll={(e) => {
                            const { contentOffset: { y }, layoutMeasurement: viewSize } = e.nativeEvent;
                            if (Math.abs(y - this.moveOffset) >= 10) {
                                this.moveOffset = y;
                                this.tagViewRefs.forEach(i => { i && i._onScroll(); });
                            }
                        }}
                        keyExtractor={(item, idx) => item.key || idx}
                        numColumns={1}
                        initialNumToRender={2}
                        showsVerticalScrollIndicator={false}
                        refreshing={this.state.isRefreshing}
                        onRefresh={this._onRefresh}
                    />
                )
        }
    }

    render() {
        return (
            <>
                {
                    this.renderContent()

                }
            </>

        );
    }
}