import { PureComponent } from "react";
import { FlatList, View } from "react-native";
import { axiosAPI } from "../common/API";
import ComicItem from "../common/ComicItem";
import { CommonStyles } from '../common/CommonStyle';
import ListFooter from "../common/ListFooter";
import VideoItem from "../common/VideoItem";
import TopLoading from "../components/TopLoading";
import px from "../utils/px";

export default class PayVideo extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            dataList: [],
            showFoot: 0,
            isRefreshing: false
        }

        this.totalPages = 0;
        this.currPage = 0;

    }


    componentDidMount() {
        this._loadData()
    }

    _loadData = async () => {
        const { data: { data, count, page, pageSize } ,error } = await axiosAPI.post('playlist', { page: this.currPage, pageSize: 10 });
        if (error) {
            $toast(error.message)
            return;
        }
        this.currPage = page;
        this.setState({
            dataList: this.state.dataList.concat(data),
            showFoot: Math.ceil(count / pageSize) > this.currPage ? 0 : 1,
            isRefreshing: false
        });
    }

    _renderItem = ({ item }) => {
        return (
            <VideoItem item={item} width={px(352)} />
        )

    };


    /**标签下拉刷新 */
    _onRefresh = () => {
        if (!this.state.isRefreshing) {
            this.setState({
                isRefreshing: false,
            })
        }
    }
    /**标签上滑刷新 */
    _onEndReached = () => {
        if (this.state.showFoot != 0) {
            return;
        }
        this.currPage++;
        this.setState({ showFoot: 2 }, () => {
            this._loadData();
        });

    };

    render() {
        return (
            <View style={CommonStyles.body}>
                <FlatList
                    ref={ref => (this._list = ref)}
                    keyExtractor={item => item.id}
                    data={this.state.dataList}
                    style={{ flex: 1 }}
                    contentContainerStyle={{
                        alignItems: 'center'
                    }}
                    renderItem={this._renderItem}
                    getItemLayout={(data, index) => (
                        { length: 150, offset: 150 * index, index }
                    )}
                    numColumns={2}
                    refreshing={this.state.isRefreshing}
                    showsVerticalScrollIndicator={false}
                    extraData={this.state.dataList}
                    // ListHeaderComponent={() => <TopLoading data={this.state.dataList} text={'正在加载'} />}
                    ListFooterComponent={() => <ListFooter showFoot={this.state.showFoot} page={this.currPage} />}
                    onRefresh={this._onRefresh}
                    onEndReached={this._onEndReached}
                    onEndReachedThreshold={0.3}
                />
            </View>
        )
    }
}