/** 公共样式表 **/
import { StyleSheet } from 'react-native';
import px from '../utils/px';

export const BaseStyles = {
    title_color: "#000",
    body_color: "#0A0A0A",
    container_color: "#0A0A0A",
    
    input_color: '#1a1a1a',
    input_text_color: '#777880',
    
    tagItem_bg_color:'#1a1a1a',
    tag_title_color: '#fff',
    name_color:'#fff',

    tab_background_color:'#0A0A0A',
    tab_active_color: "#FF9900",
    tab_inactive_color:'#fff',

    tab_bottom_color:'#0A0A0A'

};

export const CommonStyles = StyleSheet.create(
    {
        /**容器**/
        container: {
            flex: 1,
            backgroundColor: BaseStyles.container_color
        },
        body: {
            flex: 1,
            backgroundColor: BaseStyles.body_color
        },

        /***文本大小和颜色*/
        text_white: { fontSize: px(22), color: '#ffffff' },
    });
