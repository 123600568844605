/**
 * Created by 69466 on 2018/9/13.
 */
import { Video } from "expo-av";
import VideoPlayer from "expo-video-player";
import React, { Component } from "react";
import { ActivityIndicator, Pressable, View } from "react-native";
import { mv } from "../utils/ResourceHelper"

export default class PrepareVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: null,
            show: false,
            loading: false
        };
        this.playStatus = 'wait';
    }

    play = () => {
        if (this.playStatus != 'wait') {
            return;
        }
        this.playStatus = 'loading';
        setTimeout(() => {
            if (this.playStatus != 'loading') {
                return
            }
            this.setState({ loading: true }, () => {
                if (this.playStatus != 'loading') {
                    return;
                }
                mv(this.props.id).then(url => {
                    if (this.playStatus == 'loading') {
                        this.setState({
                            url,
                            show: true
                        }, () => {
                            this.playStatus = 'play';
                        })
                    } else {
                        this.playStatus == 'wait'
                    }
                })
            })
        }, 500)
    }

    stop = () => {
        if (this.playStatus == 'wait') {
            return;
        }
        this.playStatus = 'cancelling'
        this.setState({
            url: null,
            loading: false,
            show: false
        }, () => {
            this.playStatus = 'wait';
        })
    }

    render() {
        return (
            <View style={[this.props.style, {
                position: 'absolute'
            }]}>
                
                {
                    this.state.show ?

                        <VideoPlayer
                            videoProps={{
                                isLooping: true,
                                shouldPlay: true,
                                resizeMode: Video.RESIZE_MODE_CONTAIN,
                                source: { uri: this.state.url },
                                onReadyForDisplay: () => {
                                    this.setState({ loading: false })
                                }
                            }}
                            slider={{
                                visible: false,
                            }}
                            fullscreen={{
                                visible: false,
                            }}
                            timeVisible={false}
                            style={this.props.style}
                            icon={{
                                size: 0
                            }}
                        /> : null
                }
                <Pressable style={[this.props.style, {
                    position: 'absolute'
                }]} onPress={this.props.onPress}></Pressable>
                
                {this.state.loading ? <View style={[this.props.style, {
                    position: 'absolute',
                    alignItems:'center',
                    justifyContent:'center'
                }]}>
                     <ActivityIndicator size="large" color="#fff" /> 
                </View> : null}
               
            </View>
        )
    }
}
