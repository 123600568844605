import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import px from "../utils/px"
import navigate from './NavigatorUtil';
import CustomButton from './CustomButton';
import { BaseStyles } from './CommonStyle';
import FastImage from '../components/FastImage';

export default class RankActorItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ref = React.createRef()
    }

    render() {
        const width = this.props.width || px(352);
        const { nameCN, tags, avatar ,product} = this.props.item;
        return (
            <CustomButton
                onPress={() => {
                    navigate('ActorDetail', { item : this.props.item});
                }}
            >
                <View style={{ flexDirection:'row' , marginTop:px(20) , paddingHorizontal:px(20)}} 
                    onLayout={({nativeEvent:{layout:{height,top}}})=>{
                        this._height = height;
                        this._top = top;
                    }}
                >
                    <>
                        <View style={{ backgroundColor:'#000'}}>
                            <FastImage
                                url={avatar}
                                style={{
                                    width,
                                    height: width,
                                    borderRadius:2
                                }}
                                onRef={this.ref}
                            />
                        </View>
                        <View style={styles.bottomView}>
                            <Text numberOfLines={1} style={{ fontSize: px(22), color: BaseStyles.name_color, fontWeight: 'bold', fontFamily: "System" }}>{nameCN}</Text>
                            
                            <View style={styles.tagContent}>
                                {
                                    tags.map((tag, index) => {
                                        return (
                                            <View style={styles.tagItem} key={index.toString()}>
                                                <Text style={styles.keyText} >{tag}</Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                            <Text style={{ fontFamily: '', color: '#ffa31a', fontSize: px(20) }}>作品数: {product || 0}</Text>
                        </View>
    
                    </>
                    
                </View>
            </CustomButton>
        )
    }
}


const styles = StyleSheet.create({
    bottomView: {
        marginLeft:px(8),
        width:px(200),
        justifyContent:'space-around'
    },

    tagContent: {
        marginTop: px(5),
        flexDirection: 'row',
        overflow: 'hidden'
    },

    tagItem: {
        backgroundColor: BaseStyles.tagItem_bg_color,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
        margin: px(2),
        paddingHorizontal: px(5)
    },

    keyText: {
        color: '#999999',
        fontSize: px(20),
        fontFamily: 'System',
        fontWeight: 'bold'
    },
});