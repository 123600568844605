import React from 'react';
import { SafeAreaView, StatusBar} from 'react-native';
import AppContainer from "./src/common/AppNavigators";
import { BaseStyles } from './src/common/CommonStyle';
import CustomWebView from './src/components/CustomWebView';
import Loading from './src/components/Loading';
import ModalTip from './src/components/ModalTip';
import Toast from './src/components/Toast';

Array.prototype.randomMember = function(){
  return this[Math.floor(Math.random()*this.length)];
}

const App = () => {
  return (
    <SafeAreaView style={{flex: 1 ,backgroundColor:BaseStyles.tab_background_color}}>
      <StatusBar backgroundColor ={BaseStyles.tab_background_color}/>
      <Loading />
      <Toast />
      <ModalTip />
      {/* <CustomWebView /> */}
      <AppContainer/>
    </SafeAreaView>
  );
};

export default App;