import { PureComponent } from "react";
import { FlatList, View } from "react-native";
import ComicItem from "../common/ComicItem";
import { CommonStyles } from '../common/CommonStyle';
import ListFooter from "../common/ListFooter";
import VideoItem from "../common/VideoItem";
import TopLoading from "../components/TopLoading";
import px from "../utils/px";
import { assets } from "../utils/ResourceHelper";

export default class Result extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            dataList: [],
            showFoot: 0,
            isRefreshing: false
        }

        this.totalPages = 0;
        this.currPage = 1;

        this.tag = this.props.route.params.tag;
        this.type = this.props.route.params.type;
    }


    componentDidMount() {
        this.props.navigation.setOptions({ title: this.tag.title });

        this._loadData()
    }

    _loadData = async () => {
        const { data, totalPages } = await assets({ type: this.type, key: this.tag.source, page: this.currPage, pageSize: 12 });
        this.setState({
            dataList: this.state.dataList.concat(data),
            showFoot: totalPages > this.currPage ? 0 : 1,
            isRefreshing: false
        });
    }

    _renderItem = ({ item }) => {
        if (this.type == 'comic') {
            item.id = item.id.toUpperCase();
            return (
                <ComicItem item={item} width={px(220)} />
            )
        }
        return (
            <VideoItem item={item} width={px(352)} />
        )

    };


    /**标签下拉刷新 */
    _onRefresh = () => {
        if (!this.state.isRefreshing) {
            this.setState({
                isRefreshing: false,
            })
        }
    }
    /**标签上滑刷新 */
    _onEndReached = () => {
        if (this.state.showFoot != 0) {
            return;
        }
        this.currPage++;
        this.setState({ showFoot: 2 }, () => {
            this._loadData();
        });

    };

    render() {
        return (
            <View style={CommonStyles.body}>
                <FlatList
                    ref={ref => (this._list = ref)}
                    keyExtractor={item => item.id}
                    data={this.state.dataList}
                    style={{ flex: 1 }}
                    contentContainerStyle={{
                        alignItems: 'center'
                    }}
                    renderItem={this._renderItem}
                    getItemLayout={(data, index) => (
                        { length: 150, offset: 150 * index, index }
                    )}
                    numColumns={this.type == 'comic' ? 3 : 2}
                    refreshing={this.state.isRefreshing}
                    showsVerticalScrollIndicator={false}
                    extraData={this.state.dataList}
                    ListHeaderComponent={() => <TopLoading data={this.state.dataList} text={'正在加载'} />}
                    ListFooterComponent={() => <ListFooter showFoot={this.state.showFoot} page={this.currPage} />}
                    onRefresh={this._onRefresh}
                    onEndReached={this._onEndReached}
                    onEndReachedThreshold={0.3}
                />
            </View>
        )
    }
}