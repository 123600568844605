import { FlatList, View } from 'react-native';
import VideoItem from '../common/VideoItem';
import { getItem } from '../utils/Storage'
import React, { useEffect, useState } from 'react'
import px from '../utils/px';
import { CommonStyles } from '../common/CommonStyle';

export default function Favorite({ navigation }) {
    const [data, setData] = useState([]);

    const readData = async () => {
        const empData = await getItem('Favorite');
        setData(empData);
    }

    useEffect(() => {
        readData();
    }, []);

    const renderItem = ({ item })=>{
        return(
            <VideoItem item={item} width={px(352)} />
        )
    }

    return(
        <View style={CommonStyles.body}>
            <FlatList
                data={data}
                style={{flex:1  }}
                contentContainerStyle={{alignItems:'center'}}
                keyExtractor={item => item.id}
                renderItem={renderItem}
                numColumns={2}
                showsVerticalScrollIndicator={false}
            />
        </View>
    )
}