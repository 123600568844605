import { PureComponent } from "react";
import { TouchableOpacity } from "react-native";

// 防误触的距离阈值
let PREVENTDISTANCE = 3;
// 是否禁止item点击事件
let preventOnPress = false;
// 记录onPressIn时的位置，跟onPressOut时的位置做比较，绝对值大于3，不触发onPress事件
let pressPos = {
    current: {
        x: 0,
        y: 0
    }
}

export default class CustomButton extends PureComponent {
    // 构造
    constructor(props) {
        super(props);
        // 初始状态
        this.state = {
           
        }
    }

    render() {
        return (
            <TouchableOpacity
                onPressIn={(e) => {
                    preventOnPress = false;
                    pressPos.current = {
                        x: e.nativeEvent.pageX,
                        y: e.nativeEvent.pageY,
                    }
                }}
                onPressOut={(e) => {
                    const [x, y] = [e.nativeEvent.pageX, e.nativeEvent.pageY];
                    if (
                        Math.abs(pressPos.current.x - x) > PREVENTDISTANCE ||
                        Math.abs(pressPos.current.y - y) > PREVENTDISTANCE
                    ) {
                        preventOnPress = true;
                    }
                }}
                onPress={() => {
                    !preventOnPress && this.props.onPress()
                }}
            >
                {this.props.children}
            </TouchableOpacity>
        )
    }

}
