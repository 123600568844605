import React from 'react'
import { Text, View, StyleSheet, Pressable, TextInput, Platform} from 'react-native';
import px from "../utils/px"
import API, { axiosAPI } from '../common/API';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import { getItem } from '../utils/Storage';

const validityPhone = function (str) {
    const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
    return reg.test(str)
}

const validityEmail = function (str) {
    const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return reg.test(str)
}

const validityPassWord = function (str) {
    const reg = /^[a-zA-Z\d]{6,16}$/;
    return reg.test(str)
}

export default class Bind extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            password2: '',
        }
    }

    onChangeRegPhone = (username) => {
        this.setState({ username });
    }

    onChangeRegPwd = (password) => {
        this.setState({ password });
    }

    onChangeRegPwd2 = (password2) => {
        this.setState({ password2 });
    }

    onSubmit = async () => {
        const { username , password , password2} = this.state;
        if (!validityPhone(username) && !validityEmail(username)) {
            $toast('手机号码或邮件错误')
            return;
        }
        if (password == '') {
            $toast('请输入密码')
            return;
        }
        if (!validityPassWord(password)) {
            $toast('密码长度6-16位')
            return;
        }
        if (password2 == '') {
            $toast('请再次输入密码')
            return;
        }
        if (password != password2) {
            $toast('两次输入密码不一致')
            return;
        }

       
        const { data, error } = await axiosAPI.post('Bind', { username, password })

        if (error) {
            $toast(error.message)
            return;
        }

        $toast('绑定成功');
        await axiosAPI.post(`info`, {});
        this.props.navigation.navigate('Mine');
    }

    render() {
        return (
            <View style={CommonStyles.body}>
                <View style={{
                    margin: px(30),
                }}>
                    <TextInput style={styles.inputTxt}
                        placeholder={'请输入您的邮箱或手机号'}
                        blurOnSubmit={true}
                        value={this.state.username}
                        placeholderTextColor={BaseStyles.input_text_color}
                        onChangeText={(v) => this.onChangeRegPhone(v)}
                        underlineColorAndroid="transparent"
                        selectTextOnFocus={false}
                        keyboardType={'numeric'}
                    />
                    <View style={{
                        marginTop: px(30),
                    }}>
                        <TextInput style={styles.inputTxt}
                            placeholder={'请输入您的密码'}
                            value={this.state.password}
                            placeholderTextColor={BaseStyles.input_text_color}
                            onChangeText={(v) => this.onChangeRegPwd(v)}
                            underlineColorAndroid="transparent"
                            secureTextEntry={true}
                        />
                    </View>
                    <View style={{
                        marginVertical: px(30),
                    }}>
                        <TextInput style={styles.inputTxt}
                            placeholder={'请再次输入您的密码'}
                            value={this.state.password2}
                            placeholderTextColor={BaseStyles.input_text_color}
                            onChangeText={(v) => this.onChangeRegPwd2(v)}
                            underlineColorAndroid="transparent"
                            secureTextEntry={true}
                        />
                    </View>
                    <Pressable onPress={this.onSubmit} style={{
                        backgroundColor: '#FF9900',
                        padding: px(30),
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 4
                    }}>
                        <Text style={{
                            color: '#fff'
                        }}>绑定账号</Text>

                    </Pressable>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <Text onPress={() => {
                            this.props.navigation.navigate("Login")
                        }} style={{
                            padding: px(40),
                            color: '#fff',
                            fontSize: px(28)
                        }}>已有账号?直接登录</Text>
                    </View>
                </View>
            </View>
        )
    }

}

const styles = StyleSheet.create({
    inputTxt: {
        fontSize: px(28),
        color: '#fff',
        backgroundColor: BaseStyles.input_color,
        borderRadius: 4,
        padding: px(20),
        // borderWidth:1,
        // borderColor:'#fff'
    },

    loginBtn: {
        backgroundColor: '#FF9900',
        paddingVertical: px(30),
        width: px(200),
        marginLeft: px(30),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4
    }
});