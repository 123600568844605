import React, { Component } from 'react';
import { Animated, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { TabView, TabBar } from 'react-native-tab-view';
import API from '../common/API';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import { windowWidth } from '../common/Platform';
import TopLoading from '../components/TopLoading';
import Events from '../utils/events';
import px from '../utils/px';
import TagPage from './TagPage';

export default class Home extends Component {

    
    constructor(props) {
        super(props);

        this.state = {
            index: this.props.route.params?.idx || 0,
            routes: []
        }
    }

    componentDidMount = async () => {
        Events.addListener('changeHotTab', this._change)
        this.setState({ routes : $appData.hotIndex});
    };

    componentWillUnmount(){
        Events.removeListener('changeHotTab',this._change)
    }

    _change=(index)=>{
        this.setState({index})
    }

    renderScene = ({ route }) => {
        return (
            <TagPage route={route} />
        )
    };

    renderTabBar = props => (
        <TabBar
            {...props}
            indicatorStyle={{ height:0}}
            activeColor= {BaseStyles.tab_active_color}
            inactiveColor= {BaseStyles.tab_inactive_color}
            scrollEnabled
            tabStyle={{
                width: 'auto'
            }}
            style={{ backgroundColor: BaseStyles.tab_background_color }}
            renderLabel={({ route, focused, color }) => (
                <View style = {{ flexDirection:'row' ,alignItems:'center' ,alignSelf:'center'}}>
                    {route.title == '日本AV' ? <Image style={{ width: px(30), height: px(30)}} source={require("../../assets/lishijil.png")} /> : null}
                    <Text style={{ color , fontSize: px(34) ,fontWeight: 'bold', fontFamily: "System"}} >
                    {route.title}
                    </Text>
                </View>
              )}
        />
    );

    render() {
        return (
            <View style={CommonStyles.body}>
                {
                    this.state.routes.length > 0 ? <TabView
                        renderTabBar={this.renderTabBar}
                        navigationState={this.state}
                        renderScene={this.renderScene}
                        onIndexChange={this._change}
                        initialLayout={{ width: windowWidth ,height:0}}
                        swipeEnabled ={false}
                        lazy
                        lazyPreloadDistance={0}
                        renderLazyPlaceholder={()=> <TopLoading />}
                    /> : <TopLoading />
                }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    tabBar: {
      flexDirection: 'row',
      width:windowWidth * 0.9
    },
    tabItem: {
      flex: 1,
      alignItems: 'center',
      padding: 16,
    },
  });