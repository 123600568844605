
import moment from 'moment';
import { Linking } from 'react-native';
import navigate from '../common/NavigatorUtil';
import Events from './events';

function timeFilter(seconds) {
    var ss = parseInt(seconds)// 秒
    var mm = 0// 分
    var hh = 0// 小时
    if (ss > 60) {
        mm = parseInt(ss / 60)
        ss = parseInt(ss % 60)
    }
    if (mm > 60) {
        hh = parseInt(mm / 60)
        mm = parseInt(mm % 60)
    }
    var result = ('00' + parseInt(ss)).slice(-2)
    if (mm > 0) {
        result = ('00' + parseInt(mm)).slice(-2) + ':' + result
    } else {
        result = '00:' + result
    }
    if (hh > 0) {
        result = ('00' + parseInt(hh)).slice(-2) + ':' + result
    }
    return result
}


function getVipTime(date) {
    const vipDate = moment(date);
    const isVIP = vipDate.isAfter(moment());
    return { isVIP, vipDate: vipDate.format('YYYY-MM-DD HH:mm:ss') }
}

// nav://order
// nav://member
// nav://mine?
// search://(comic/video/actor)?(tag/keyword)=xxx
function click(url) {
    // url = 'link://Result?tag={"title":"麻豆视频","source":"a8b338e2b8f385666fb43bbc76a60176"}';
    // url = 'nav://Hot/2';
    // url = 'nav://Mine';
    // url = 'search://国产';
    // url = 'http://www.baidu.com';
    let [urlType, target] = url.split("://");
    switch (urlType) {
        case "link":
            {
                const reg = /(\w+)\?(\w+)=(.*)/;
                const [_, route, key, value] = target.match(reg);
                const obj = JSON.parse(value);
                navigate(route, { [key] : obj});
            }
            break;
        case "nav":
            {
                const [_, route, idx] = target.match(/(\w+)\/?(\w+)?/);
                if(idx){
                    navigate(route,{idx : parseInt(idx)});

                    const EVENT_TAB = {
                        'Home' : 'changeHomeTab',
                        'Hot' : 'changeHotTab',
                        'Comic' : 'changeComicTab',
                    }
                    Events.emit(EVENT_TAB[route],parseInt(idx))
                }else{
                    navigate(route);
                }
            }
            break;
        case "search":
            navigate("Search", {text : target});
            break;
        case "https":
        case "http":
            Linking.openURL(url)
            break;
        case "!https":
        case "!http":
            navigate("Mine", {  });
            break;
    }
}

function randomString(max) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < max; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function isEmpty(thing){
    // Check for undefined, null and NaN
    if (typeof thing === 'undefined' || thing === null ||
        (typeof thing === 'number' && isNaN(thing))) {
        return true;
    }

    // Numbers, booleans, functions and DOM nodes are never judged to be empty
    else if (typeof thing === 'number' || typeof thing === 'boolean' ||
        typeof thing === 'function' || thing.nodeType === 1) {
        return false;
    }

    // Check for arrays with zero length
    else if (thing.constructor === Array && thing.length < 1) {
        return true;
    }

    // Check for empty strings after accounting for whitespace
    else if (typeof thing === 'string') {
        if (thing.replace(/\s+/g, '') === '') {
            return true;
        }
        else {
            return false;
        }
    }

    // Check for objects with no properties, accounting for natives like window and XMLHttpRequest
    else if (Object.prototype.toString.call(thing).slice(8, -1) === 'Object') {
        var props = 0;
        for (var prop in thing) {
            if (thing.hasOwnProperty(prop)) {
                props++;
            }
        }
        if (props < 1) {
            return true;
        }
    }

    // If we've got this far, the thing is not null or empty
    return false;
};

export default {
    timeFilter,
    getVipTime,
    randomString,
    isEmpty,
    click
};