import React, { Component } from "react";
import { Modal, StyleSheet, Text, View } from "react-native";
import px from "../utils/px";
import Events from "../utils/events";


export default class Toast extends Component {
  constructor() {
    super();
    this.state = {
      modalVisible: false,
      toastText: ''
    };
  }

  componentDidMount() {
    Events.addListener("toast", this._toast)
  }

  _toast = (text) => {
    this.setState({
      modalVisible: true,
      toastText: text
    },()=>{
        setTimeout(()=>{
            this.setState({
                modalVisible: false,
                toastText: ''
            })
        },2000)
    })
  }


  render() {
    const { modalVisible, toastText } = this.state;
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => { }}
      >
       <View style={styles.modalView}>
            <View style={styles.content}>
                <Text>{toastText}</Text>
            </View>
       </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  content: {
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:20,
      paddingRight:20,
      borderRadius:4,
      backgroundColor:'#fff',
      fontSize:16
  },
  
});