import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import API from '../common/API';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';

export default class Fuli extends Component {
    constructor(props) {
        super(props);

        this.state = {
           
        }
    }

    componentDidMount = async () => {

    };

    render() {
        return (
            <View style={CommonStyles.body}>
               
            </View>
        );
    }
}

const styles = StyleSheet.create({
   
  });