
import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Animated,
  Modal,
  ScrollView,
} from 'react-native';
import px from '../utils/px';
import { windowHeight, windowWidth } from './Platform';

const leftWidth = windowWidth - 150;
const leftSpace = 100;
const duration = 200;

export default class ContentModal extends Component {

  constructor(props) {
    super(props);

    this.marginLeftValue = new Animated.Value(0); // 左侧向右动画初始值，默认为0

    this.state = {
      isShowModal: false,
    };

  }

  /*显示浮层*/
  showModalByFadeIn(idx) {
    this.setState({
      isShowModal: true,
      currentIdx: idx
    }, () => {
      this.marginLeftValue.setValue(0);
        // 从左向右的动画效果
        Animated.timing(
          this.marginLeftValue,
          {
            toValue: 1,
            duration: duration,
            // easing: Easing.linear,
            useNativeDriver: false
          }
        ).start()
    });

  }

  /*隐藏浮层*/
  hideModalByFadeIn() {
      Animated.timing(
        this.marginLeftValue,
        {
          toValue: 0,
          duration: duration,
          // easing: Easing.linear,
          useNativeDriver: false
        }
      ).start(() => {
      this.setState({
        isShowModal: false,
      })
    });

  }

  render() {

    const movingMargin = this.marginLeftValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-leftWidth, 0]
    });

    return (
        <Modal style={styles.modalStyle}
          visible={this.state.isShowModal}
          transparent={true}
          animationType='fade'
        >
          <View style={{
            marginTop: 0,
            marginLeft: 0,
            width: windowWidth,
            height: windowHeight,
            backgroundColor: this.state.isShowModal ? 'rgba(0, 0, 0 ,0.5)' : 'rgba(0, 0, 0 ,0)',
            flexDirection: 'row',
          }}>
            {/*动画View*/}
            <Animated.View style={{
              marginTop: 0,
              marginLeft: movingMargin,
              width: leftWidth,
              height: windowHeight,
              backgroundColor: 'white',
              
            }}>
              <>
                <View style={{flexDirection :'row', justifyContent:'space-between',paddingVertical:px(30),borderBottomWidth:1, borderColor:'#f2f2f2'}}>

                 <Text style={{ paddingHorizontal: px(20),fontFamily: '', fontSize: px(32),  fontWeight:'bold',  color: "#313131" }}>共{this.props.allContent.length}话</Text>
                 {/* <Text style={{ paddingHorizontal: px(20),fontFamily: '', fontSize: px(26) , fontWeight:'100', color: "#313131" }}>倒序</Text> */}
                 </View>
                 <View style={{ height: windowHeight - px(200)}}>

                <ScrollView>

                {
                  this.props.allContent.map((item, idx) => {
                    return (
                      <TouchableOpacity key={idx.toString()} onPress={() => {
                        this.props.onPress(idx)
                        this.hideModalByFadeIn()
                      }}>
                        <View style={{
                          paddingHorizontal: px(20),
                        }}>
                          <View style={{borderBottomWidth:1,borderColor:'#f2f2f2' ,  paddingVertical:px(30),flexDirection:'row' ,alignItems:'center'}}>
                            <Text style={{ marginRight:px(20),fontFamily: '', fontSize: px(28), fontWeight:'300',color: idx == this.state.currentIdx ? "#ff3333" :"#313131" }}>第{item.chapter_num}话</Text>
                            <Text numberOfLines={1} style={{  width: px(300),fontFamily: '', fontSize: px(26),fontWeight:'300',color: idx == this.state.currentIdx ? "#ff3333" :"#313131"}}>{item.chapter_name}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    )
                  })
                }

                </ScrollView>
                </View>

              </>
            </Animated.View>

            {/*右侧点击按钮*/}
            <TouchableOpacity style={styles.rightStyle}
              onPress={() => {
                this.hideModalByFadeIn();
              }}
              activeOpacity={1}
            />

          </View>

        </Modal>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  textStyle: {
    marginTop: 200,
    marginLeft: 100,
    textAlign: 'center',
    backgroundColor: 'red',
    height: 44,
    lineHeight: 44
  },
  modalStyle: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  downViewStyle: {
    height: 50,
    marginHorizontal: 0,
    backgroundColor: 'green',
    marginTop: windowHeight - 50,
    alignItems: 'center',
  },
  rightStyle: {
    marginTop: 0,
    marginRight: 0,
    width: leftSpace,
    height: windowHeight,
  }

});