import React from "react";
import {
    Image,
    Pressable
} from "react-native";
import { goBack } from "../common/NavigatorUtil";
import px from "./px";

export default class ViewUtils {
    static getLeftButton() {
        return (
            <Pressable style={{padding:px(20)}} onPress={()=>{
                goBack()
            }}>
                <Image
                    style={{width: px(38), height: px(29)}}
                    source={require("../../assets/btn_back_default.png")}
                />
            </Pressable>
        );
    }

}