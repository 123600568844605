import React from 'react'
import { View, Image, TouchableOpacity, TextInput, StyleSheet } from 'react-native';
import px from "../utils/px"
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import SearchContent from '../common/SearchContent';

export default class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            text: ""
        }
        this.type = this.props.route.params?.type;

    }

    componentDidMount =  () => {
        if(this.props.route.params?.text){
            this.contentRef.loadVideoByText(this.props.route.params.text)
        }
    };

    _loadSearch=(text)=>{
        this.setState({
            index
        })
    }


    onChangeText = (text) => {
        this.setState({ text });
        this.contentRef.changeKeyWords(text)
    }

    goSearch = (key) => {
        this.contentRef.loadVideoData()
    }

    render() {
        return (
            <View style={CommonStyles.body}>
                <View style={styles.topContent} >
                    <TouchableOpacity style={{ paddingLeft: px(20), paddingRight: px(40), paddingVertical: px(10) }} onPress={() => { this.props.navigation.goBack() }} >
                        <Image
                            style={{ width: px(38), height: px(35) }}
                            source={require("../../assets/btn_back_default.png")}
                        />
                    </TouchableOpacity>
                    
                    <TextInput style={styles.inputTxt}
                        ref="searchinput"
                        placeholder={'输入关键字查找'}
                        value={this.state.text}
                        placeholderTextColor={BaseStyles.input_text_color}
                        onSubmitEditing={() => { this.goSearch() }}
                        clearButtonMode= 'never'
                        onChangeText={(v) => this.onChangeText(v)}
                        underlineColorAndroid="transparent"
                        selectTextOnFocus={false}
                        // onFocus={() => {
                        //     this.onChangeText('')
                        // }}
                    />
                    <TouchableOpacity
                        style={{ paddingVertical: px(10), paddingHorizontal: px(20) }}
                        onPress={() => {
                            this.goSearch();
                        }}
                    >
                        <Image style={{ width: px(40), height: px(40) }} source={require("../../assets/sous.png")} />
                    </TouchableOpacity>
                </View>
                <SearchContent ref={ref=>this.contentRef = ref} type={this.type}/>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    topContent: {
        alignItems: "center",
        flexDirection: "row",
        marginVertical: px(10)
    },
    inputTxt: {
        fontSize: px(28),
        color: BaseStyles.input_text_color,
        backgroundColor: BaseStyles.input_color,
        paddingHorizontal: px(20),
        flex: 1,
        paddingVertical:px(5),
        borderRadius: 5,
    }
});
