import { EventEmitter } from "events";
const Events = new EventEmitter();


//显示loading
function ld(text , callback){
    Events.emit('loading',{bl: true, text , callback})
}

//关闭
function cld(){
    Events.emit('loading',{bl: false})
}

//提示
function toast(text){
    Events.emit('toast',text)
}

//弹窗
function box(options){
    Events.emit('modal',options)
}

function webview(url){
    Events.emit('webview',url)
}


global.$ld = ld;
global.$cld = cld;
global.$toast = toast;
global.$box = box;
global.$webview = webview;


export default Events;