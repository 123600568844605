import React, { Component } from 'react';
import {
    Image,
    View,
    Text,
    TouchableOpacity,
    Platform,
    PermissionsAndroid,
    ScrollView,
    Pressable,
} from 'react-native';
import { windowWidth } from '../common/Platform';
import px from '../utils/px';
import CustomQrCode from '../components/CustomQrCode';
import * as Clipboard from 'expo-clipboard';
import * as MediaLibrary from 'expo-media-library';
import ViewShot from "react-native-view-shot";

export default class Share extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    SaveToPhone = async () => {
        if (Platform.OS === 'web') {
            $toast('手动截屏')
        } else if (Platform.OS === 'android') {
            try {
                const granted = await PermissionsAndroid.request(
                    PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE)
                if (granted === 'granted' || granted === true) {
                    this.createImage();
                }
                else {
                    $toast('无权限')
                }
            } catch (err) {

            }
        }
        else {
            this.createImage();
        }
    }

    createImage = () => {
        this.viewShot.capture().then(
            async (uri) => {
                const asset = await MediaLibrary.createAssetAsync(uri);
                MediaLibrary.createAlbumAsync('Images', asset, false)
                    .then(() => {
                        $toast('保存成功')
                    })
                    .catch(() => {
                        $toast('保存失败')
                    });
            }
        ).catch(
            (error) => $toast(error)
        );
    }

    copyLink = () => {
        Clipboard.setString($appConfig.share + 'inviter=' + $infoData.inviter);
        $toast('复制成功')
    }

    render() {
        return (
            <ScrollView>

                <ViewShot ref={ref => this.viewShot = ref}>
                    <View>

                        <Image style={{ width: px(750), height: px(1101), alignSelf: 'center' }} source={require("../../assets/010.jpg")} />
                        <Pressable onPress={() => { this.props.navigation.goBack() }} style={{ position: 'absolute', left: 0, top: 0, padding: px(30) }}>
                            <Image
                                style={{ width: px(50), height: px(50) }}
                                source={require("../../assets/btn_back_default.png")}
                            />
                        </Pressable>
                        {/* <Text onPress={()=>{console.log(222222222)}} style={{fontSize:px(34), color:'#fff',position: 'absolute', right: 0, top: 0 , padding:px(30)}}>我的推广</Text> */}
                        <View style={{ alignItems: 'center', bottom: px(110), position: 'absolute' }}>
                            <View style={{ borderRadius: 5, borderWidth: 6, borderColor: '#F7301A' }}>
                                <CustomQrCode text={$appConfig.share + 'inviter=' + $infoData.inviter} style={{ width: px(400), height: px(400) }} />
                            </View>
                            <View style={{ width: windowWidth, marginTop: px(30), justifyContent: "space-around", flexDirection: 'row', alignItems: 'center' }}>
                                <Pressable
                                    onPress={() => {
                                        this.SaveToPhone()
                                    }}
                                    style={{
                                        paddingVertical: px(20),
                                        paddingHorizontal: px(80),
                                        backgroundColor: '#F7301A',
                                        borderRadius: 23,
                                    }} >
                                    <Text style={{ textAlign: 'center', fontSize: px(30), color: "#fff", fontWeight: "bold", fontFamily: "System" }} >保存二维码</Text>
                                </Pressable>
                                <Pressable
                                    onPress={() => {
                                        this.copyLink()
                                    }}
                                    style={{
                                        paddingVertical: px(20),
                                        paddingHorizontal: px(80),
                                        backgroundColor: '#F7301A',
                                        borderRadius: 23,
                                    }} >
                                    <Text style={{ textAlign: 'center', fontSize: px(30), color: "#fff", fontWeight: "bold", fontFamily: "System" }} >复制链接</Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </ViewShot>

                <Image style={{ width: px(750), height: px(1535), alignSelf: 'center' }} source={require("../../assets/120.jpg")} />
            </ScrollView>

        )
    }

}
