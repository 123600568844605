import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * 获取存储的数据
 * @param {*} key
 */
export async function getItem(key) {
    try {
        const jsonValue = await AsyncStorage.getItem(key);
        return jsonValue != null ? JSON.parse(jsonValue) : null
    } catch (e) {
        console.log("read error")
    }
}
/**
 * 存入数据
 * @param {*} key
 * @param {*} value
 */
export async function setItem(key, value) {
    try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem(key, jsonValue)
    } catch (e) {
        console.log("save error")
    }
};

/**
 * 删除已经存在的数据
 * @param {*} key
 */
export async function removeItem(key) {
    AsyncStorage.removeItem(key);
}
/**
 * 清除所有
 */
export async function clear() {
    AsyncStorage.clear();
}

/**
 * 获取所有的key
 */
export async function getAllKeys() {
    AsyncStorage.getAllKeys();
}


