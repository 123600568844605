import React from 'react';
import { FlatList, View ,Text} from 'react-native';
import { BaseStyles } from '../common/CommonStyle';
import CustomButton from '../common/CustomButton';
import navigate from '../common/NavigatorUtil';
import FastImage from '../components/FastImage';
import px from '../utils/px';

export default class ActorView extends React.PureComponent {

    renderItem = ({ item }) => {
        return (
            <CustomButton
                onPress={() => {
                    navigate('ActorDetail', { item });
                }}
            >
                <View style={{ margin: px(10)}} >
                    <FastImage
                        url={item.avatar}
                        style={{
                            width: px(220),
                            height: px(220),
                            borderRadius:5,

                        }}
                        onRef={this.ref}
                    />
                    <Text numberOfLines={1} style={{ marginTop:px(5),fontSize: px(22), color: BaseStyles.name_color, fontWeight: 'bold', fontFamily: "System" }}>{item.nameCN}</Text>
                </View>
            </CustomButton>
        )
    }

    render() {
        return (
            <FlatList
                data={this.props.content}
                style={{ flex: 1 }}
                contentContainerStyle={{
                    alignItems:'center',
                }}
                renderItem={this.renderItem}
                keyExtractor={(item,idx) => item.key || idx}
                numColumns={3}
                // initialNumToRender={3}
                showsVerticalScrollIndicator={false}
            />
        );
    }
}